import React from "react";
import { Box, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { InfoDarkIcon } from "../../../Icons";
import { UseFormReturn } from "react-hook-form";
import { ReportType } from "../../../../types/reportTypes";
import { metricsMediaOptionsData } from "../../../../utils/data";

const ReportsStepThree = ({
  reportStepperForm,
}: {
  reportStepperForm: UseFormReturn<ReportType>;
}) => {
  const { watch, setValue } = reportStepperForm;

  const selectedMetrics = watch("selectedMetrics") || [];

  const handleCheckboxChange = (name: string) => {
    const isSelected = selectedMetrics.includes(name);

    if (isSelected) {
      setValue(
        "selectedMetrics",
        selectedMetrics.filter((metric: string) => metric !== name)
      );
    } else {
      setValue("selectedMetrics", [...selectedMetrics, name]);
    }
  };
  return (
    <Box className="grid grid-cols-1 md:grid-cols-2 gap-5">
      {metricsMediaOptionsData.map((option, index) => (
        <Box
          key={index}
          className="flex-between-center w-full border rounded-xl p-5 border-primary-blue-100"
        >
          <Box className="flex items-center gap-[5px]">
            <Checkbox
              checked={selectedMetrics.includes(option)}
              onChange={() => handleCheckboxChange(option)}
            />
            <Typography>{option}</Typography>
          </Box>
          {InfoDarkIcon}
        </Box>
      ))}
    </Box>
  );
};

export default ReportsStepThree;
