export const BellIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame">
      <path
        id="Vector"
        d="M9.57792 20.1C10.2234 20.6602 11.0714 21 12 21C12.9287 21 13.7766 20.6602 14.422 20.1M17.4926 8.4C17.4926 6.96783 16.9139 5.59432 15.8839 4.58162C14.8538 3.56893 13.4567 3 12 3C10.5433 3 9.14621 3.56893 8.11615 4.58162C7.08609 5.59432 6.5074 6.96783 6.5074 8.4C6.5074 11.1812 5.7938 13.0854 4.99663 14.3449C4.32422 15.4073 3.98801 15.9385 4.00033 16.0867C4.01399 16.2508 4.04934 16.3133 4.18383 16.4114C4.30529 16.5 4.85284 16.5 5.94794 16.5H18.0521C19.1471 16.5 19.6947 16.5 19.8161 16.4114C19.9507 16.3133 19.986 16.2508 19.9997 16.0867C20.012 15.9385 19.6758 15.4073 19.0034 14.3449C18.2062 13.0854 17.4926 11.1812 17.4926 8.4Z"
        stroke="#20407D"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        id="Ellipse 278"
        cx="16"
        cy="4"
        r="2.5"
        fill="#FF4C40"
        stroke="#F4F5F8"
      />
    </g>
  </svg>
);

export const PlusIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame">
      <path
        id="Vector"
        d="M12 5V19M5 12H19"
        stroke="#20407D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);
export const ArrowDownIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="input/vuesax/linear/Property 35">
      <g id="vuesax/linear/arrow-down">
        <g id="arrow-down">
          <path
            id="Vector"
            d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
            stroke="#20407D"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const ArrowRightIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M7.5 15.5L12.5 10.5L7.5 5.5"
      stroke="#4D6697"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const DashboardIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20.3333 11.0834V5.41675C20.3333 4.16675 19.8 3.66675 18.475 3.66675H15.1083C13.7833 3.66675 13.25 4.16675 13.25 5.41675V11.0834C13.25 12.3334 13.7833 12.8334 15.1083 12.8334H18.475C19.8 12.8334 20.3333 12.3334 20.3333 11.0834Z"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M20.3333 18.5833V17.0833C20.3333 15.8333 19.8 15.3333 18.475 15.3333H15.1083C13.7833 15.3333 13.25 15.8333 13.25 17.0833V18.5833C13.25 19.8333 13.7833 20.3333 15.1083 20.3333H18.475C19.8 20.3333 20.3333 19.8333 20.3333 18.5833Z"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.7503 12.9167V18.5834C10.7503 19.8334 10.217 20.3334 8.89199 20.3334H5.52533C4.20033 20.3334 3.66699 19.8334 3.66699 18.5834V12.9167C3.66699 11.6667 4.20033 11.1667 5.52533 11.1667H8.89199C10.217 11.1667 10.7503 11.6667 10.7503 12.9167Z"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M10.7503 5.41675V6.91675C10.7503 8.16675 10.217 8.66675 8.89199 8.66675H5.52533C4.20033 8.66675 3.66699 8.16675 3.66699 6.91675V5.41675C3.66699 4.16675 4.20033 3.66675 5.52533 3.66675H8.89199C10.217 3.66675 10.7503 4.16675 10.7503 5.41675Z"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const CalenderIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame" clip-path="url(#clip0_384_21921)">
      <g id="Group 33">
        <path
          id="Vector"
          d="M11.2453 20H4.875C4.37772 20 3.90081 19.8025 3.54917 19.4508C3.19754 19.0992 3 18.6223 3 18.125V6.875C3 6.37772 3.19754 5.90081 3.54917 5.54917C3.90081 5.19754 4.37772 5 4.875 5H16.125C16.6223 5 17.0992 5.19754 17.4508 5.54917C17.8025 5.90081 18 6.37772 18 6.875V10.625"
          stroke="#20407D"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Union"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.5 13.375C16.406 13.375 15.3568 13.8096 14.5832 14.5832C13.8096 15.3568 13.375 16.406 13.375 17.5C13.375 18.594 13.8096 19.6432 14.5832 20.4168C15.3568 21.1904 16.406 21.625 17.5 21.625C18.594 21.625 19.6432 21.1904 20.4168 20.4168C21.1904 19.6432 21.625 18.594 21.625 17.5C21.625 16.406 21.1904 15.3568 20.4168 14.5832C19.6432 13.8096 18.594 13.375 17.5 13.375ZM16.375 14.8542C16.0391 14.9971 15.7302 15.2039 15.4671 15.4671C14.9279 16.0062 14.625 16.7375 14.625 17.5C14.625 18.2625 14.9279 18.9938 15.4671 19.5329C16.0062 20.0721 16.7375 20.375 17.5 20.375C18.2625 20.375 18.9938 20.0721 19.5329 19.5329C20.0426 19.0233 20.3411 18.342 20.3723 17.625H17C16.6548 17.625 16.375 17.3452 16.375 17V14.8542ZM20.1458 16.375C20.0029 16.0391 19.7961 15.7302 19.5329 15.4671C19.0233 14.9574 18.342 14.6589 17.625 14.6277V16.375H20.1458Z"
          fill="#20407D"
        />
        <path
          id="Vector_2"
          d="M15 3V7"
          stroke="#20407D"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_3"
          d="M7 3V7"
          stroke="#20407D"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_4"
          d="M3 10H18"
          stroke="#20407D"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_384_21921">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ListIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20.17 7.94533V15.7247C20.17 17.2807 20.17 18.0587 19.8672 18.653C19.6008 19.1758 19.1758 19.6008 18.653 19.8672C18.0587 20.17 17.2807 20.17 15.7247 20.17H7.94533C6.38932 20.17 5.61131 20.17 5.017 19.8672C4.49422 19.6008 4.06919 19.1758 3.80282 18.653C3.5 18.0587 3.5 17.2807 3.5 15.7247V7.94533C3.5 6.38932 3.5 5.61131 3.80282 5.017C4.06919 4.49422 4.49422 4.06919 5.017 3.80282C5.61131 3.5 6.38932 3.5 7.94533 3.5H15.7247C17.2807 3.5 18.0587 3.5 18.653 3.80282C19.1758 4.06919 19.6008 4.49422 19.8672 5.017C20.17 5.61131 20.17 6.38932 20.17 7.94533Z"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M14 12H8M10 16H8M16 8H8"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const MegaphoneIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M17.5739 12.8764C19.2812 12.8764 20.6653 11.4923 20.6653 9.785C20.6653 8.07764 19.2812 6.69357 17.5739 6.69357M10.2869 5.8103H6.9747C4.77953 5.8103 3 7.58983 3 9.785C3 11.9802 4.77953 13.7597 6.9747 13.7597H10.2869C11.8471 13.7597 13.7557 14.596 15.2282 15.3988C16.0872 15.867 16.5167 16.1011 16.798 16.0667C17.0589 16.0347 17.2561 15.9176 17.409 15.7038C17.5739 15.4734 17.5739 15.0122 17.5739 14.0898V5.48021C17.5739 4.55783 17.5739 4.09663 17.409 3.86613C17.2561 3.65239 17.0589 3.53527 16.798 3.50332C16.5167 3.46887 16.0872 3.703 15.2282 4.17128C13.7557 4.97394 11.8471 5.8103 10.2869 5.8103Z"
      stroke="#20407D"
      stroke-width="1.10408"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M4.7666 13.3181L6.15744 18.8814C6.19655 19.0378 6.2161 19.1161 6.23924 19.1844C6.46527 19.8517 7.0662 20.3208 7.7684 20.3784C7.84028 20.3842 7.92092 20.3842 8.0822 20.3842C8.28415 20.3842 8.38514 20.3842 8.47021 20.376C9.311 20.2944 9.9764 19.629 10.0579 18.7882C10.0662 18.7031 10.0662 18.6021 10.0662 18.4002V5.8103"
      stroke="#20407D"
      stroke-width="1.10408"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const PlayIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      opacity="0.4"
      d="M10 9.02749C10 8.55999 10 8.32624 10.091 8.19574C10.1703 8.082 10.2917 8.01086 10.4237 8.00073C10.5752 7.98913 10.7584 8.11552 11.1247 8.36834L15.4323 11.3409C15.7502 11.5602 15.9092 11.6699 15.964 11.8094C16.012 11.9312 16.012 12.0688 15.964 12.1906C15.9092 12.3301 15.7502 12.4398 15.4323 12.6591L11.1247 15.6316C10.7584 15.8845 10.5752 16.0109 10.4237 15.9993C10.2917 15.9892 10.1703 15.918 10.091 15.8042C10 15.6738 10 15.4401 10 14.9725V9.02749Z"
      stroke="#20407D"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 8.26667C4 6.77319 4 6.02645 4.29065 5.45603C4.54631 4.95426 4.95426 4.54631 5.45603 4.29065C6.02645 4 6.77319 4 8.26667 4H15.7333C17.2268 4 17.9735 4 18.544 4.29065C19.0458 4.54631 19.4537 4.95426 19.7093 5.45603C20 6.02645 20 6.77319 20 8.26667V15.7333C20 17.2268 20 17.9735 19.7093 18.544C19.4537 19.0458 19.0458 19.4537 18.544 19.7093C17.9735 20 17.2268 20 15.7333 20H8.26667C6.77319 20 6.02645 20 5.45603 19.7093C4.95426 19.4537 4.54631 19.0458 4.29065 18.544C4 17.9735 4 17.2268 4 15.7333V8.26667Z"
      stroke="#20407D"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const TreeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      opacity="0.4"
      d="M5.6 16.8V16.64C5.6 15.2958 5.6 14.6238 5.86158 14.1104C6.09168 13.6588 6.45883 13.2917 6.91042 13.0616C7.42381 12.8 8.09587 12.8 9.44 12.8H14.56C15.9042 12.8 16.5762 12.8 17.0896 13.0616C17.5412 13.2917 17.9083 13.6588 18.1384 14.1104C18.4 14.6238 18.4 15.2958 18.4 16.64V16.8M5.6 16.8C4.71634 16.8 4 17.5163 4 18.4C4 19.2837 4.71634 20 5.6 20C6.48366 20 7.2 19.2837 7.2 18.4C7.2 17.5163 6.48366 16.8 5.6 16.8ZM18.4 16.8C17.5163 16.8 16.8 17.5163 16.8 18.4C16.8 19.2837 17.5163 20 18.4 20C19.2837 20 20 19.2837 20 18.4C20 17.5163 19.2837 16.8 18.4 16.8ZM12 16.8C11.1163 16.8 10.4 17.5163 10.4 18.4C10.4 19.2837 11.1163 20 12 20C12.8837 20 13.6 19.2837 13.6 18.4C13.6 17.5163 12.8837 16.8 12 16.8ZM12 16.8V8.8M7.2 8.8H16.8C17.5455 8.8 17.9182 8.8 18.2123 8.67821C18.6043 8.51582 18.9158 8.20434 19.0782 7.8123C19.2 7.51826 19.2 7.1455 19.2 6.4C19.2 5.6545 19.2 5.28174 19.0782 4.9877C18.9158 4.59566 18.6043 4.28418 18.2123 4.12179C17.9182 4 17.5455 4 16.8 4H7.2C6.4545 4 6.08174 4 5.7877 4.12179C5.39566 4.28418 5.08418 4.59566 4.92179 4.9877C4.8 5.28174 4.8 5.6545 4.8 6.4C4.8 7.1455 4.8 7.51826 4.92179 7.8123C5.08418 8.20434 5.39566 8.51582 5.7877 8.67821C6.08174 8.8 6.4545 8.8 7.2 8.8Z"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.1998 8.8H16.7998C17.5453 8.8 17.918 8.8 18.2121 8.67821C18.6041 8.51582 18.9156 8.20434 19.078 7.8123C19.1998 7.51826 19.1998 7.1455 19.1998 6.4C19.1998 5.6545 19.1998 5.28174 19.078 4.9877C18.9156 4.59566 18.6041 4.28418 18.2121 4.12179C17.918 4 17.5453 4 16.7998 4H7.1998C6.4543 4 6.08155 4 5.78751 4.12179C5.39547 4.28418 5.08399 4.59566 4.9216 4.9877C4.7998 5.28174 4.7998 5.6545 4.7998 6.4C4.7998 7.1455 4.7998 7.51826 4.9216 7.8123C5.08399 8.20434 5.39547 8.51582 5.78751 8.67821C6.08155 8.8 6.4543 8.8 7.1998 8.8Z"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const BarGraphIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19 7.84V16.16C19 17.5042 19 18.1762 18.7139 18.6896C18.4622 19.1412 18.0607 19.5083 17.5667 19.7384C17.0052 20 16.2702 20 14.8 20H9.2C7.72986 20 6.99479 20 6.43328 19.7384C5.93935 19.5083 5.53778 19.1412 5.28611 18.6896C5 18.1762 5 17.5042 5 16.16V7.84C5 6.49587 5 5.82381 5.28611 5.31042C5.53778 4.85883 5.93935 4.49168 6.43328 4.26158C6.99479 4 7.72986 4 9.2 4H14.8C16.2702 4 17.0052 4 17.5667 4.26158C18.0607 4.49168 18.4622 4.85883 18.7139 5.31042C19 5.82381 19 6.49587 19 7.84Z"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 13.8V17M15 12.2V17M12 9V17"
      stroke="#20407D"
      stroke-opacity="0.4"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const BookIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M19.25 14.7256V5.24373C19.25 4.30105 18.4415 3.60189 17.4597 3.68045H17.4103C15.6778 3.82185 13.046 4.66241 11.5775 5.54225L11.4372 5.62866C11.198 5.77006 10.802 5.77006 10.5628 5.62866L10.3565 5.51082C8.888 4.63884 6.2645 3.80614 4.532 3.67259C3.55025 3.59403 2.75 4.30105 2.75 5.23587V14.7256C2.75 15.4797 3.3935 16.1867 4.1855 16.281L4.42475 16.3124C6.215 16.5402 8.97875 17.4044 10.5628 18.2292L10.5958 18.2449C10.8185 18.3628 11.1733 18.3628 11.3878 18.2449C12.9718 17.4122 15.7438 16.5402 17.5423 16.3124L17.8145 16.281C18.6065 16.1867 19.25 15.4797 19.25 14.7256Z"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M11 6.41663L11 18.3333"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M7.33333 8.25H5.5"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M8.25 11H5.5"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ChatsIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M16.485 11.0925V14.0925C16.485 14.2875 16.4775 14.475 16.455 14.655C16.2825 16.68 15.09 17.685 12.8925 17.685H12.5925C12.405 17.685 12.225 17.775 12.1125 17.925L11.2125 19.125C10.815 19.6575 10.17 19.6575 9.7725 19.125L8.87249 17.925C8.77499 17.7975 8.5575 17.685 8.3925 17.685H8.09251C5.70001 17.685 4.5 17.0925 4.5 14.0925V11.0925C4.5 8.89501 5.51251 7.70251 7.53001 7.53001C7.71001 7.50751 7.89751 7.5 8.09251 7.5H12.8925C15.285 7.5 16.485 8.70001 16.485 11.0925Z"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M19.4853 8.09251V11.0925C19.4853 13.2975 18.4728 14.4825 16.4553 14.655C16.4778 14.475 16.4853 14.2875 16.4853 14.0925V11.0925C16.4853 8.70001 15.2853 7.5 12.8928 7.5H8.09277C7.89777 7.5 7.71027 7.50751 7.53027 7.53001C7.70277 5.51251 8.89527 4.5 11.0928 4.5H15.8928C18.2853 4.5 19.4853 5.70001 19.4853 8.09251Z"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M13.1216 12.9375H13.1284"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M10.4966 12.9375H10.5034"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M7.87162 12.9375H7.87837"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const InfoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 4C18.4 4 20 5.6 20 12C20 18.4 18.4 20 12 20C5.6 20 4 18.4 4 12C4 5.6 5.6 4 12 4Z"
      stroke="#20407D"
      stroke-opacity="0.3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M11 11H12.5V15H14" fill="#888888" fill-opacity="0.3" />
    <path
      d="M11 11H12.5V15H14"
      stroke="#20407D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="12" cy="8" r="0.5" fill="#888888" stroke="#20407D" />
  </svg>
);

export const ShieldIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M10.5974 2.92267L5.96129 4.66933C4.89286 5.06883 4.01953 6.33237 4.01953 7.46584V14.3689C4.01953 15.4652 4.74421 16.9052 5.62683 17.5649L9.62184 20.5472C10.9318 21.532 13.0873 21.532 14.3973 20.5472L18.3923 17.5649C19.2749 16.9052 19.9996 15.4652 19.9996 14.3689V7.46584C19.9996 6.32308 19.1263 5.05954 18.0578 4.66004L13.4217 2.92267C12.632 2.63466 11.3685 2.63466 10.5974 2.92267Z"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M9.25977 11.879L10.7556 13.3748L14.7506 9.37976"
      stroke="#20407D"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const NavigationButtonRectangle = (
  <svg
    width="4"
    height="44"
    viewBox="0 0 4 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Rectangle 20971"
      d="M0 4C0 1.79086 1.79086 0 4 0V44C1.79086 44 0 42.2091 0 40V4Z"
      fill="#20407D"
    />
  </svg>
);

export const GrowthChartSmallGreenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M15.3333 6L10.0876 11.2457C9.8236 11.5097 9.69153 11.6418 9.53933 11.6912C9.40547 11.7347 9.2612 11.7347 9.12733 11.6912C8.97513 11.6418 8.84307 11.5097 8.57907 11.2457L6.75425 9.42093C6.49023 9.15693 6.35823 9.02487 6.20601 8.97547C6.07211 8.93193 5.92789 8.93193 5.79399 8.97547C5.64177 9.02487 5.50977 9.15693 5.24575 9.42093L2 12.6667M15.3333 6H10.6667M15.3333 6V10.6667"
      stroke="#0BAF7E"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const GrowthChartSmallYellowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M15.3333 12L10.0876 6.75427C9.8236 6.49027 9.69153 6.3582 9.53933 6.3088C9.40547 6.26527 9.2612 6.26527 9.12733 6.3088C8.97513 6.3582 8.84307 6.49027 8.57907 6.75427L6.75425 8.57907C6.49023 8.84307 6.35823 8.97513 6.20601 9.02453C6.07211 9.06807 5.92789 9.06807 5.79399 9.02453C5.64177 8.97513 5.50977 8.84307 5.24575 8.57907L2 5.33333M15.3333 12H10.6667M15.3333 12V7.33333"
      stroke="#EB8F05"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const GrowthChartBigBlueIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      d="M33 10.5L21.1971 22.3029C20.6031 22.8969 20.306 23.1941 19.9635 23.3052C19.6623 23.4032 19.3377 23.4032 19.0365 23.3052C18.694 23.1941 18.3969 22.8969 17.8029 22.3029L13.6971 18.1971C13.103 17.6031 12.806 17.3059 12.4635 17.1948C12.1623 17.0968 11.8377 17.0968 11.5365 17.1948C11.194 17.3059 10.897 17.6031 10.3029 18.1971L3 25.5M33 10.5H22.5M33 10.5V21"
      stroke="#20407D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const CursorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      d="M22.5 5.25V3M28.409 7.59099L30 6M28.409 19.5L30 21.0911M16.5 7.59099L14.9089 6M30.75 13.5H33M12.2032 24.2844L15.941 31.2255C16.3679 32.0185 16.5815 32.415 16.8383 32.5154C17.0612 32.6024 17.3122 32.5777 17.514 32.4492C17.7466 32.301 17.8791 31.8706 18.1443 31.0099L23.3322 14.1679C23.5488 13.4648 23.6571 13.1132 23.57 12.8757C23.4941 12.6689 23.3311 12.5059 23.1243 12.4301C22.8868 12.3429 22.5353 12.4512 21.8321 12.6678L4.9902 17.8557C4.1295 18.1209 3.69915 18.2535 3.55095 18.486C3.42225 18.6879 3.3978 18.9388 3.4848 19.1617C3.585 19.4187 3.9816 19.6321 4.7745 20.0592L11.7156 23.7968C11.8335 23.8602 11.8925 23.8919 11.9439 23.9327C11.9898 23.9688 12.0312 24.0102 12.0673 24.0561C12.1081 24.1075 12.1398 24.1665 12.2032 24.2844Z"
      stroke="#20407D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const DollarIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 33C26.2842 33 33 26.2842 33 18C33 9.71572 26.2842 3 18 3C9.71572 3 3 9.71572 3 18C3 26.2842 9.71572 33 18 33Z"
      stroke="#20407D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13 21.3333C13 23.1742 14.4924 24.6666 16.3333 24.6666H19.6667C21.5076 24.6666 23 23.1742 23 21.3333C23 19.4924 21.5076 18 19.6667 18H16.3333C14.4924 18 13 16.5075 13 14.6666C13 12.8257 14.4924 11.3333 16.3333 11.3333H19.6667C21.5076 11.3333 23 12.8257 23 14.6666M18 9.66663V26.3333"
      stroke="#20407D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const GoogleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_384_27573)">
      <path
        d="M5.31857 14.5036L4.48314 17.6224L1.42966 17.687C0.51712 15.9944 -0.000488281 14.0579 -0.000488281 12C-0.000488281 10.0101 0.483463 8.13355 1.3413 6.4812H1.34196L4.06041 6.97959L5.25126 9.68173C5.00202 10.4084 4.86617 11.1884 4.86617 12C4.86626 12.8809 5.02583 13.725 5.31857 14.5036Z"
        fill="#FBBB00"
      />
      <path
        d="M23.7905 9.7583C23.9283 10.4842 24.0002 11.2339 24.0002 12.0001C24.0002 12.8593 23.9099 13.6973 23.7378 14.5057C23.1536 17.2565 21.6272 19.6586 19.5126 21.3584L19.512 21.3578L16.0879 21.1831L15.6033 18.1579C17.0064 17.335 18.103 16.0473 18.6806 14.5057H12.2637V9.7583H23.7905Z"
        fill="#518EF8"
      />
      <path
        d="M19.5124 21.3578L19.5131 21.3585C17.4566 23.0115 14.8442 24.0005 12.0004 24.0005C7.43035 24.0005 3.45706 21.4462 1.43018 17.6872L5.31909 14.5038C6.33251 17.2085 8.9416 19.1338 12.0004 19.1338C13.3151 19.1338 14.5468 18.7784 15.6038 18.1579L19.5124 21.3578Z"
        fill="#28B446"
      />
      <path
        d="M19.6601 2.76246L15.7725 5.94518C14.6787 5.26144 13.3856 4.86646 12.0004 4.86646C8.87234 4.86646 6.21445 6.88013 5.2518 9.68179L1.34245 6.48126H1.3418C3.33901 2.6306 7.36241 -0.000244141 12.0004 -0.000244141C14.9121 -0.000244141 17.5818 1.03694 19.6601 2.76246Z"
        fill="#F14336"
      />
    </g>
    <defs>
      <clipPath id="clip0_384_27573">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const FacebookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M15.7742 26.6277L15.7747 26.6276C21.9764 25.7636 26.75 20.4406 26.75 14.0004C26.75 6.9587 21.0404 1.25 13.9996 1.25C6.9587 1.25 1.25 6.95877 1.25 14.0004C1.25 20.3468 5.8869 25.6098 11.9588 26.5874C12.624 26.6947 13.3064 26.75 14.0004 26.75C14.602 26.75 15.1947 26.7081 15.7742 26.6277Z"
      fill="#1877F2"
      stroke="white"
      stroke-width="1.5"
    />
    <path
      d="M15.6706 11.6349V14.2491H18.9044L18.3923 17.7705H15.6706V25.8837C15.1249 25.9594 14.5665 25.9989 13.9998 25.9989C13.3456 25.9989 12.7032 25.9468 12.0777 25.8459V17.7705H9.09521V14.2491H12.0777V11.0505C12.0777 9.06615 13.6862 7.45679 15.6714 7.45679V7.45847C15.6773 7.45847 15.6823 7.45679 15.6882 7.45679H18.9053V10.5023H16.8032C16.1784 10.5023 15.6714 11.0093 15.6714 11.6341L15.6706 11.6349Z"
      fill="white"
    />
  </svg>
);
export const InstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M14.0273 1.25H13.9727C6.94614 1.25 1.25 6.94614 1.25 13.9727V14.0273C1.25 21.0539 6.94614 26.75 13.9727 26.75H14.0273C21.0539 26.75 26.75 21.0539 26.75 14.0273V13.9727C26.75 6.94614 21.0539 1.25 14.0273 1.25Z"
      fill="url(#paint0_linear_384_27583)"
      stroke="white"
      stroke-width="1.5"
    />
    <path
      d="M17.7144 6.88293H10.2881C8.23644 6.88293 6.56738 8.552 6.56738 10.6036V17.3976C6.56738 19.4492 8.23644 21.1183 10.2881 21.1183H17.7144C19.766 21.1183 21.4351 19.4492 21.4351 17.3976V10.6036C21.4351 8.552 19.766 6.88293 17.7144 6.88293ZM7.87993 10.6036C7.87993 9.27596 8.9604 8.19548 10.2881 8.19548H17.7144C19.042 8.19548 20.1225 9.27596 20.1225 10.6036V17.3976C20.1225 18.7253 19.042 19.8058 17.7144 19.8058H10.2881C8.9604 19.8058 7.87993 18.7253 7.87993 17.3976V10.6036Z"
      fill="white"
    />
    <path
      d="M14.0019 17.4603C15.9098 17.4603 17.4628 15.9082 17.4628 13.9995C17.4628 12.0908 15.9106 10.5386 14.0019 10.5386C12.0932 10.5386 10.541 12.0908 10.541 13.9995C10.541 15.9082 12.0932 17.4603 14.0019 17.4603ZM14.0019 11.852C15.1866 11.852 16.1502 12.8156 16.1502 14.0003C16.1502 15.185 15.1866 16.1486 14.0019 16.1486C12.8172 16.1486 11.8536 15.185 11.8536 14.0003C11.8536 12.8156 12.8172 11.852 14.0019 11.852Z"
      fill="white"
    />
    <path
      d="M17.7826 11.0976C18.2963 11.0976 18.7151 10.6797 18.7151 10.1652C18.7151 9.65056 18.2972 9.23267 17.7826 9.23267C17.268 9.23267 16.8501 9.65056 16.8501 10.1652C16.8501 10.6797 17.268 11.0976 17.7826 11.0976Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_384_27583"
        x1="5.50629"
        y1="22.4937"
        x2="22.4937"
        y2="5.50713"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FAAD4F" />
        <stop offset="0.35" stop-color="#DD2A7B" />
        <stop offset="0.62" stop-color="#9537B0" />
        <stop offset="1" stop-color="#515BD4" />
      </linearGradient>
    </defs>
  </svg>
);
export const TikTokIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_384_27590)">
      <path
        d="M11.9865 23.8412C5.43931 23.8412 0.131836 18.5337 0.131836 11.9865C0.131836 5.43931 5.43931 0.131836 11.9865 0.131836C18.5337 0.131836 23.8412 5.43931 23.8412 11.9865C23.8412 18.5337 18.5337 23.8412 11.9865 23.8412Z"
        fill="black"
      />
      <path
        d="M10.6747 10.5611V10.0374C10.4933 10.0118 10.3099 9.99846 10.1265 9.99744C7.88322 9.99744 6.05811 11.8226 6.05811 14.0658C6.05811 15.442 6.74573 16.6595 7.79509 17.3963C7.09313 16.6451 6.70166 15.6552 6.70269 14.6263C6.70371 12.4159 8.47657 10.6123 10.6747 10.5611Z"
        fill="#00F2EA"
      />
      <path
        d="M10.7711 16.4843C11.7723 16.4843 12.588 15.6881 12.6259 14.6961L12.629 5.83899H14.2471C14.2122 5.65351 14.1948 5.46598 14.1948 5.27844H11.9844L11.9803 14.1355C11.9434 15.1275 11.1267 15.9238 10.1265 15.9238C9.82622 15.9238 9.53006 15.851 9.26465 15.7106C9.61307 16.1964 10.1736 16.4843 10.7711 16.4843ZM17.2691 8.84567V8.35378C16.6748 8.35481 16.0927 8.18162 15.5947 7.85574C16.0312 8.35788 16.6194 8.70528 17.2691 8.84567Z"
        fill="#00F2EA"
      />
      <path
        d="M15.5957 7.85569C15.1079 7.29719 14.8394 6.58088 14.8394 5.83997H14.2471C14.4018 6.66798 14.8886 7.39557 15.5957 7.85569ZM10.1265 12.2079C9.10067 12.2089 8.26958 13.04 8.26855 14.0658C8.26958 14.7565 8.65284 15.3898 9.26463 15.7106C9.03611 15.3949 8.91314 15.0168 8.91314 14.6274C8.91416 13.6016 9.74525 12.7705 10.771 12.7695C10.9627 12.7695 11.1461 12.8012 11.3193 12.8556V10.599C11.1379 10.5734 10.9545 10.5601 10.771 10.559C10.7393 10.559 10.7075 10.5611 10.6747 10.5611V12.294C10.4974 12.2376 10.313 12.2089 10.1265 12.2079Z"
        fill="#FF004F"
      />
      <path
        d="M17.2689 8.84558V10.5631C16.1232 10.5631 15.0615 10.1962 14.1946 9.57419V14.0658C14.1946 16.309 12.3705 18.1341 10.1273 18.1341C9.2603 18.1341 8.45688 17.8605 7.7959 17.3963C8.56345 18.2243 9.64151 18.6947 10.7708 18.6947C13.014 18.6947 14.8392 16.8695 14.8392 14.6273V10.1358C15.7348 10.7804 16.8108 11.1257 17.9135 11.1247V8.91424C17.6921 8.91424 17.4769 8.88965 17.2689 8.84558Z"
        fill="#FF004F"
      />
      <path
        d="M14.1947 14.0658V9.57423C15.0903 10.2188 16.1664 10.5642 17.269 10.5631V8.84562C16.6183 8.70523 16.0311 8.35783 15.5945 7.85569C14.8885 7.39557 14.4017 6.66798 14.247 5.83997H12.6288L12.6258 14.6971C12.5889 15.689 11.7721 16.4853 10.7709 16.4853C10.1735 16.4843 9.61294 16.1963 9.26554 15.7106C8.65375 15.3898 8.27049 14.7565 8.26946 14.0658C8.27049 13.04 9.10158 12.2089 10.1274 12.2079C10.318 12.2079 10.5024 12.2397 10.6756 12.294V10.5611C8.47647 10.6123 6.70361 12.4159 6.70361 14.6274C6.70361 15.6962 7.11865 16.6698 7.79602 17.3973C8.47852 17.878 9.29321 18.1352 10.1274 18.1342C12.3696 18.1342 14.1947 16.309 14.1947 14.0658Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_384_27590">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const RedirectIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M21 9.00012V3.00012M21 3.00012H15M21 3.00012L13 11.0001M10 5.00012H7.8C6.11984 5.00012 5.27976 5.00012 4.63803 5.3271C4.07354 5.61472 3.6146 6.07366 3.32698 6.63815C3 7.27988 3 8.11996 3 9.80012V16.2001C3 17.8803 3 18.7203 3.32698 19.3621C3.6146 19.9266 4.07354 20.3855 4.63803 20.6731C5.27976 21.0001 6.11984 21.0001 7.8 21.0001H14.2C15.8802 21.0001 16.7202 21.0001 17.362 20.6731C17.9265 20.3855 18.3854 19.9266 18.673 19.3621C19 18.7203 19 17.8803 19 16.2001V14.0001"
      stroke="#798CB1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const GalleryIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
  >
    <path
      d="M3.27209 18.728L9.8686 12.1315C10.2646 11.7355 10.4627 11.5374 10.691 11.4633C10.8918 11.398 11.1082 11.398 11.309 11.4633C11.5373 11.5374 11.7354 11.7355 12.1314 12.1315L18.6839 18.684M13 13.0001L15.8686 10.1315C16.2646 9.73552 16.4627 9.53742 16.691 9.46332C16.8918 9.39802 17.1082 9.39802 17.309 9.46332C17.5373 9.53742 17.7354 9.73552 18.1314 10.1315L21 13.0001M9 7.00012C9 8.10472 8.10457 9.00012 7 9.00012C5.89543 9.00012 5 8.10472 5 7.00012C5 5.89555 5.89543 5.00012 7 5.00012C8.10457 5.00012 9 5.89555 9 7.00012ZM5.8 19.0001H16.2C17.8802 19.0001 18.7202 19.0001 19.362 18.6731C19.9265 18.3855 20.3854 17.9266 20.673 17.3621C21 16.7203 21 15.8803 21 14.2001V5.80012C21 4.11996 21 3.27988 20.673 2.63815C20.3854 2.07366 19.9265 1.61472 19.362 1.3271C18.7202 1.00012 17.8802 1.00012 16.2 1.00012H5.8C4.11984 1.00012 3.27976 1.00012 2.63803 1.3271C2.07354 1.61472 1.6146 2.07366 1.32698 2.63815C1 3.27988 1 4.11996 1 5.80012V14.2001C1 15.8803 1 16.7203 1.32698 17.3621C1.6146 17.9266 2.07354 18.3855 2.63803 18.6731C3.27976 19.0001 4.11984 19.0001 5.8 19.0001Z"
      stroke="white"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const AddWithBoxIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 8.44444V15.5556M8.44444 12H15.5556M8.26667 20H15.7333C17.2268 20 17.9735 20 18.544 19.7093C19.0458 19.4537 19.4537 19.0458 19.7093 18.544C20 17.9735 20 17.2268 20 15.7333V8.26667C20 6.77319 20 6.02645 19.7093 5.45603C19.4537 4.95426 19.0458 4.54631 18.544 4.29065C17.9735 4 17.2268 4 15.7333 4H8.26667C6.77319 4 6.02645 4 5.45603 4.29065C4.95426 4.54631 4.54631 4.95426 4.29065 5.45603C4 6.02645 4 6.77319 4 8.26667V15.7333C4 17.2268 4 17.9735 4.29065 18.544C4.54631 19.0458 4.95426 19.4537 5.45603 19.7093C6.02645 20 6.77319 20 8.26667 20Z"
      stroke="#20407D"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const CustomBlueDeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15.1111 7.2V6.56C15.1111 5.66392 15.1111 5.21587 14.9416 4.87362C14.7925 4.57255 14.5545 4.32778 14.2618 4.17439C13.929 4 13.4934 4 12.6222 4H11.3778C10.5066 4 10.071 4 9.73824 4.17439C9.44554 4.32778 9.20757 4.57255 9.05844 4.87362C8.88889 5.21587 8.88889 5.66392 8.88889 6.56V7.2M10.4444 11.6V15.6M13.5556 11.6V15.6M5 7.2H19M17.4444 7.2V16.16C17.4444 17.5042 17.4444 18.1762 17.1901 18.6896C16.9664 19.1412 16.6095 19.5083 16.1704 19.7384C15.6713 20 15.0179 20 13.7111 20H10.2889C8.9821 20 8.3287 20 7.82958 19.7384C7.39053 19.5083 7.03358 19.1412 6.80987 18.6896C6.55556 18.1762 6.55556 17.5042 6.55556 16.16V7.2"
      stroke="#20407D"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const MobileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 17.5H12.01M8.2 22H15.8C16.9201 22 17.4802 22 17.908 21.782C18.2843 21.5903 18.5903 21.2843 18.782 20.908C19 20.4802 19 19.9201 19 18.8V5.2C19 4.07989 19 3.51984 18.782 3.09202C18.5903 2.71569 18.2843 2.40973 17.908 2.21799C17.4802 2 16.9201 2 15.8 2H8.2C7.0799 2 6.51984 2 6.09202 2.21799C5.71569 2.40973 5.40973 2.71569 5.21799 3.09202C5 3.51984 5 4.0799 5 5.2V18.8C5 19.9201 5 20.4802 5.21799 20.908C5.40973 21.2843 5.71569 21.5903 6.09202 21.782C6.51984 22 7.07989 22 8.2 22ZM12.5 17.5C12.5 17.7761 12.2761 18 12 18C11.7239 18 11.5 17.7761 11.5 17.5C11.5 17.2239 11.7239 17 12 17C12.2761 17 12.5 17.2239 12.5 17.5Z"
      stroke="#20407D"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const TabletIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M17.5 12L17.5 11.99M22 16.8L22 7.2C22 6.0799 22 5.5198 21.782 5.092C21.5903 4.7157 21.2843 4.4097 20.908 4.218C20.4802 4 19.9201 4 18.8 4L5.2 4C4.07989 4 3.51984 4 3.09202 4.218C2.71569 4.4097 2.40973 4.7157 2.21799 5.092C2 5.5198 2 6.0799 2 7.2L2 16.8C2 17.9201 2 18.4802 2.21799 18.908C2.40973 19.2843 2.71569 19.5903 3.09202 19.782C3.51984 20 4.0799 20 5.2 20L18.8 20C19.9201 20 20.4802 20 20.908 19.782C21.2843 19.5903 21.5903 19.2843 21.782 18.908C22 18.4802 22 17.9201 22 16.8ZM17.5 11.5C17.7761 11.5 18 11.7239 18 12C18 12.2761 17.7761 12.5 17.5 12.5C17.2239 12.5 17 12.2761 17 12C17 11.7239 17.2239 11.5 17.5 11.5Z"
      stroke="#4B73B8"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ComputerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_384_27540)">
      <path
        d="M3 5C3 4.73478 3.10536 4.48043 3.29289 4.29289C3.48043 4.10536 3.73478 4 4 4H20C20.2652 4 20.5196 4.10536 20.7071 4.29289C20.8946 4.48043 21 4.73478 21 5V15C21 15.2652 20.8946 15.5196 20.7071 15.7071C20.5196 15.8946 20.2652 16 20 16H4C3.73478 16 3.48043 15.8946 3.29289 15.7071C3.10536 15.5196 3 15.2652 3 15V5Z"
        stroke="#8D9DD0"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 20H17"
        stroke="#8D9DD0"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 16V20"
        stroke="#8D9DD0"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 16V20"
        stroke="#8D9DD0"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_384_27540">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CalenderTimeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_1878_5404)">
      <path
        d="M11.2453 20H4.875C4.37772 20 3.90081 19.8025 3.54917 19.4508C3.19754 19.0992 3 18.6223 3 18.125V6.875C3 6.37772 3.19754 5.90081 3.54917 5.54917C3.90081 5.19754 4.37772 5 4.875 5H16.125C16.6223 5 17.0992 5.19754 17.4508 5.54917C17.8025 5.90081 18 6.37772 18 6.875V10.625"
        stroke="#20407D"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5 13.375C16.406 13.375 15.3568 13.8096 14.5832 14.5832C13.8096 15.3568 13.375 16.406 13.375 17.5C13.375 18.594 13.8096 19.6432 14.5832 20.4168C15.3568 21.1904 16.406 21.625 17.5 21.625C18.594 21.625 19.6432 21.1904 20.4168 20.4168C21.1904 19.6432 21.625 18.594 21.625 17.5C21.625 16.406 21.1904 15.3568 20.4168 14.5832C19.6432 13.8096 18.594 13.375 17.5 13.375ZM16.375 14.8542C16.0391 14.9971 15.7302 15.2039 15.4671 15.4671C14.9279 16.0062 14.625 16.7375 14.625 17.5C14.625 18.2625 14.9279 18.9938 15.4671 19.5329C16.0062 20.0721 16.7375 20.375 17.5 20.375C18.2625 20.375 18.9938 20.0721 19.5329 19.5329C20.0426 19.0233 20.3411 18.342 20.3723 17.625H17C16.6548 17.625 16.375 17.3452 16.375 17V14.8542ZM20.1458 16.375C20.0029 16.0391 19.7961 15.7302 19.5329 15.4671C19.0233 14.9574 18.342 14.6589 17.625 14.6277V16.375H20.1458Z"
        fill="#20407D"
      />
      <path
        d="M15 3V7"
        stroke="#20407D"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 3V7"
        stroke="#20407D"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 10H18"
        stroke="#20407D"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1878_5404">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const MetaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
  >
    <path
      d="M6.88844 24.8325C6.88844 26.2147 7.19015 27.2714 7.5845 27.9123C7.79465 28.2771 8.09727 28.5796 8.46162 28.789C8.82597 28.9985 9.23906 29.1074 9.65892 29.1047C10.673 29.1047 11.6006 28.8534 13.3884 26.3705C14.8206 24.3814 16.5081 21.5956 17.6449 19.8364L19.5678 16.871C20.9036 14.8115 22.4472 12.5221 24.2224 10.9652C25.6684 9.70366 27.2295 9 28.8007 9C31.4384 9 33.951 10.5342 35.874 13.4117C37.9784 16.5631 39.0038 20.5326 39.0038 24.6289C39.0038 27.0641 38.5255 28.8534 37.7118 30.267C36.9268 31.6341 35.3895 33 32.8118 33V29.1047C35.0189 29.1047 35.566 27.0691 35.566 24.7395C35.566 21.4197 34.7948 17.7355 33.096 15.103C31.8904 13.2358 30.328 12.0949 28.6091 12.0949C26.75 12.0949 25.254 13.5022 23.5727 16.0115C22.6788 17.3447 21.7612 18.9694 20.7308 20.8027L19.5966 22.8132C17.3181 26.8681 16.741 27.7916 15.6018 29.3158C13.6087 31.9948 11.9036 33 9.65892 33C6.9961 33 5.31228 31.8427 4.2757 30.0986C3.41814 28.6775 3 26.8128 3 24.688L6.88844 24.8325Z"
      fill="#0081FB"
    />
    <path
      d="M6.06592 13.6869C7.84864 10.9288 10.4213 9 13.3721 9C15.0809 9 16.7798 9.50262 18.5537 10.9615C20.4942 12.551 22.5599 15.1684 25.1426 19.4821L26.0677 21.0302C28.3011 24.7646 29.5731 26.6846 30.3155 27.5918C31.2719 28.7554 31.943 29.0997 32.8118 29.0997C35.0189 29.0997 35.566 27.0641 35.566 24.7344L38.9962 24.6264C38.9962 27.0616 38.518 28.8509 37.7043 30.2645C36.9268 31.6341 35.3895 33 32.8118 33C31.2093 33 29.7897 32.6507 28.2198 31.1642C27.0129 30.0232 25.602 27.9964 24.5166 26.1744L21.2879 20.7613C19.6679 18.0446 18.1819 16.0191 17.3219 15.1068C16.3955 14.1154 15.2074 12.9242 13.3095 12.9242C11.7734 12.9242 10.4689 14.0061 9.37723 15.6609L6.06592 13.6869Z"
      fill="url(#paint0_linear_629_3917)"
    />
    <path
      d="M13.3095 12.9242C11.7734 12.9242 10.4689 14.0061 9.37724 15.6609C7.83363 17.9994 6.88844 21.4825 6.88844 24.8325C6.88844 26.2147 7.19015 27.2714 7.5845 27.9123L4.26944 30.0986C3.41814 28.6775 3 26.8128 3 24.688C3 20.8241 4.05661 16.7969 6.06593 13.6869C7.84866 10.9288 10.4213 9 13.3721 9L13.3095 12.9242Z"
      fill="url(#paint1_linear_629_3917)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_629_3917"
        x1="10.8044"
        y1="20.378"
        x2="35.5927"
        y2="21.6253"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0064E1" />
        <stop offset="0.4" stop-color="#0064E1" />
        <stop offset="0.83" stop-color="#0073EE" />
        <stop offset="1" stop-color="#0082FB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_629_3917"
        x1="8.18542"
        y1="26.466"
        x2="8.18542"
        y2="17.2932"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0082FB" />
        <stop offset="1" stop-color="#0064E0" />
      </linearGradient>
    </defs>
  </svg>
);

export const InfoDarkIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 4C18.4 4 20 5.6 20 12C20 18.4 18.4 20 12 20C5.6 20 4 18.4 4 12C4 5.6 5.6 4 12 4Z"
      stroke="#666666"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M11 11H12.5V15H14" fill="#666666" />
    <path
      d="M11 11H12.5V15H14"
      stroke="#666666"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="12" cy="8" r="0.5" fill="#666666" stroke="#666666" />
  </svg>
);
export const ArrowLeftIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20 12H4M4 12L10 18M4 12L10 6"
      stroke="#20407D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const ArrowRightIcon2 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
    viewBox="0 0 18 14"
    fill="none"
  >
    <path
      d="M1 7H17M17 7L11 13M17 7L11 1"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const EditIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_493_10317)">
      <path
        d="M7.14702 8.0293H6.26468C5.79666 8.0293 5.34781 8.21522 5.01686 8.54616C4.68592 8.8771 4.5 9.32596 4.5 9.79398V17.735C4.5 18.2031 4.68592 18.6519 5.01686 18.9829C5.34781 19.3138 5.79666 19.4997 6.26468 19.4997H14.2058C14.6738 19.4997 15.1226 19.3138 15.4536 18.9829C15.7845 18.6519 15.9704 18.2031 15.9704 17.735V16.8527"
        stroke="#20407D"
        stroke-width="1.23528"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.9576 7.66329C19.3051 7.31578 19.5003 6.84446 19.5003 6.35301C19.5003 5.86156 19.3051 5.39024 18.9576 5.04273C18.6101 4.69523 18.1387 4.5 17.6473 4.5C17.1558 4.5 16.6845 4.69523 16.337 5.04273L8.91211 12.4412V15.0882H11.5591L18.9576 7.66329Z"
        stroke="#20407D"
        stroke-width="1.23528"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0879 6.26465L17.7349 8.91167"
        stroke="#20407D"
        stroke-width="1.23528"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_493_10317">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CancelIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12 4L4 12M4 4L12 12"
      stroke="black"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ExportIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20 6.66667H17.1556C15.662 6.66667 14.9154 6.66667 14.3449 6.95732C13.8431 7.21298 13.4352 7.62092 13.1796 8.12269C12.8889 8.69312 12.8889 9.43986 12.8889 10.9333V12M20 6.66667L17.3333 4M20 6.66667L17.3333 9.33333M10.2222 4H8.26667C6.77319 4 6.02645 4 5.45603 4.29065C4.95426 4.54631 4.54631 4.95426 4.29065 5.45603C4 6.02645 4 6.77319 4 8.26667V15.7333C4 17.2268 4 17.9735 4.29065 18.544C4.54631 19.0458 4.95426 19.4537 5.45603 19.7093C6.02645 20 6.77319 20 8.26667 20H15.7333C17.2268 20 17.9735 20 18.544 19.7093C19.0458 19.4537 19.4537 19.0458 19.7093 18.544C20 17.9735 20 17.2268 20 15.7333V13.7778"
      stroke="white"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const SocialIconCombined = () => (
  <svg
    width="146"
    height="44"
    viewBox="0 0 146 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="22"
      cy="22"
      r="21"
      fill="#F4F5F8"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M17.546 23.669L16.9891 25.7482L14.9534 25.7912C14.3451 24.6629 14 23.3719 14 22C14 20.6734 14.3226 19.4224 14.8945 18.3208H14.895L16.7072 18.6531L17.5011 20.4545C17.335 20.9389 17.2444 21.4589 17.2444 22C17.2445 22.5872 17.3508 23.1499 17.546 23.669Z"
      fill="#FBBB00"
    />
    <path
      d="M29.8603 20.5056C29.9521 20.9896 30 21.4894 30 22.0001C30 22.5729 29.9398 23.1316 29.8251 23.6705C29.4357 25.5044 28.418 27.1057 27.0084 28.2389L27.0079 28.2385L24.7253 28.122L24.4022 26.1053C25.3376 25.5567 26.0686 24.6982 26.4537 23.6705H22.1758V20.5056H29.8603Z"
      fill="#518EF8"
    />
    <path
      d="M27.0078 28.2385L27.0083 28.2389C25.6373 29.3409 23.8957 30.0003 21.9998 30.0003C18.9532 30.0003 16.3044 28.2974 14.9531 25.7914L17.5457 23.6692C18.2213 25.4723 19.9607 26.7558 21.9998 26.7558C22.8763 26.7558 23.6975 26.5189 24.4021 26.1053L27.0078 28.2385Z"
      fill="#28B446"
    />
    <path
      d="M27.1066 15.8418L24.5149 17.9636C23.7857 17.5077 22.9237 17.2444 22.0002 17.2444C19.9148 17.2444 18.1429 18.5869 17.5012 20.4546L14.895 18.3209H14.8945C16.226 15.7539 18.9082 14 22.0002 14C23.9413 14 25.7211 14.6914 27.1066 15.8418Z"
      fill="#F14336"
    />
    <circle
      cx="56"
      cy="22"
      r="21"
      fill="#F4F5F8"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M64 22.0003C64 26.0408 61.0049 29.3811 57.1141 29.9232C56.7503 29.9737 56.3781 30 56.0003 30C55.5642 30 55.1359 29.9652 54.7188 29.898C50.9093 29.2847 48 25.9819 48 22.0003C48 17.582 51.582 14 55.9997 14C60.4175 14 64 17.582 64 22.0003Z"
      fill="#1877F2"
    />
    <path
      d="M57.114 20.4234V22.1661H59.2699L58.9286 24.5137H57.114V29.9226C56.7502 29.973 56.378 29.9994 56.0002 29.9994C55.5641 29.9994 55.1358 29.9646 54.7188 29.8973V24.5137H52.7305V22.1661H54.7188V20.0338C54.7188 18.7108 55.7911 17.6379 57.1146 17.6379V17.6391C57.1185 17.6391 57.1219 17.6379 57.1258 17.6379H59.2705V19.6683H57.8691C57.4526 19.6683 57.1146 20.0063 57.1146 20.4228L57.114 20.4234Z"
      fill="white"
    />
    <circle
      cx="90"
      cy="22"
      r="21"
      fill="#F4F5F8"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M86.4457 14H93.5962C96.0236 14 98 15.9764 98 18.4038V25.5492C98 27.9766 96.0236 29.953 93.5962 29.953H86.4508C84.0234 29.953 82.047 27.9766 82.047 25.5492V18.4038C82.047 15.9764 84.0183 14 86.4457 14ZM83.4609 25.5543C83.4609 27.2013 84.7987 28.5391 86.4457 28.5391H93.5962C95.2432 28.5391 96.5811 27.2013 96.5811 25.5543V18.4038C96.5811 16.7568 95.2432 15.4189 93.5962 15.4189H86.4508C84.8038 15.4189 83.4659 16.7568 83.4659 18.4038L83.4609 25.5543Z"
      fill="url(#paint0_linear_490_9206)"
    />
    <path
      d="M90.0249 17.8667C92.2901 17.8667 94.1348 19.7113 94.1348 21.9766C94.1348 24.2418 92.2901 26.0865 90.0249 26.0865C87.7596 26.0865 85.915 24.2418 85.915 21.9766C85.915 19.7113 87.7546 17.8667 90.0249 17.8667ZM90.0249 24.6726C91.5097 24.6726 92.7209 23.4614 92.7209 21.9766C92.7209 20.4918 91.5097 19.2806 90.0249 19.2806C88.5401 19.2806 87.3289 20.4918 87.3289 21.9766C87.3289 23.4665 88.535 24.6726 90.0249 24.6726Z"
      fill="url(#paint1_linear_490_9206)"
    />
    <path
      d="M85.7424 16.6655C86.016 16.6655 86.2846 16.777 86.4772 16.9696C86.6698 17.1622 86.7812 17.4307 86.7812 17.7044C86.7812 17.9781 86.6698 18.2466 86.4772 18.4392C86.2846 18.6318 86.016 18.7433 85.7424 18.7433C85.4687 18.7433 85.2001 18.6318 85.0076 18.4392C84.815 18.2466 84.7035 17.9781 84.7035 17.7044C84.7035 17.4307 84.815 17.1622 85.0076 16.9696C85.2001 16.777 85.4687 16.6655 85.7424 16.6655Z"
      fill="url(#paint2_linear_490_9206)"
    />
    <circle
      cx="124"
      cy="22"
      r="21"
      fill="#F4F5F8"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M116.803 27.9769L116.861 28.1325C116.853 28.1147 116.832 28.0602 116.803 27.9769ZM119.092 24.4509C119.193 23.6148 119.538 23.1467 120.188 22.6669C121.118 22.017 122.28 22.3846 122.28 22.3846V20.2037C122.563 20.1965 122.845 20.2132 123.124 20.2534V23.06C123.124 23.06 121.963 22.6924 121.033 23.3426C120.383 23.8221 120.038 24.2906 119.936 25.1266C119.933 25.5806 120.019 26.1741 120.414 26.6872C120.316 26.6375 120.217 26.5808 120.115 26.5172C119.246 25.9368 119.087 25.0662 119.092 24.4509ZM127.921 16.1713C127.282 15.4743 127.04 14.7707 126.952 14.2764H127.757C127.757 14.2764 127.597 15.5725 128.766 16.847L128.782 16.8642C128.467 16.6671 128.178 16.4344 127.921 16.1713ZM131.799 18.1472V20.8973C131.799 20.8973 130.772 20.8573 130.012 20.6651C128.95 20.3963 128.268 19.9839 128.268 19.9839C128.268 19.9839 127.797 19.6899 127.759 19.6694V25.3484C127.759 25.6646 127.672 26.4543 127.406 27.1129C127.059 27.9745 126.524 28.5401 126.426 28.6557C126.426 28.6557 125.775 29.4202 124.627 29.935C123.592 30.3994 122.684 30.3876 122.412 30.3994C122.412 30.3994 120.842 30.4612 119.429 29.5482C119.123 29.347 118.838 29.119 118.576 28.8674L118.583 28.8724C119.997 29.7854 121.567 29.7236 121.567 29.7236C121.839 29.7119 122.748 29.7236 123.782 29.2592C124.929 28.7444 125.581 27.9799 125.581 27.9799C125.678 27.8643 126.216 27.2988 126.561 26.4368C126.826 25.7785 126.914 24.9885 126.914 24.6723V18.994C126.952 19.0148 127.423 19.3088 127.423 19.3088C127.423 19.3088 128.105 19.7215 129.166 19.99C129.927 20.1822 130.954 20.2222 130.954 20.2222V18.0672C131.305 18.1455 131.604 18.1667 131.799 18.1472Z"
      fill="#EE1D52"
    />
    <path
      d="M130.954 18.0672V20.2215C130.954 20.2215 129.927 20.1815 129.166 19.9893C128.105 19.7205 127.423 19.3082 127.423 19.3082C127.423 19.3082 126.952 19.0141 126.914 18.9933V24.673C126.914 24.9892 126.827 25.7792 126.561 26.4375C126.214 27.2995 125.679 27.865 125.581 27.9806C125.581 27.9806 124.93 28.7451 123.782 29.2599C122.748 29.7243 121.839 29.7125 121.567 29.7243C121.567 29.7243 119.997 29.7861 118.584 28.8731L118.576 28.8681C118.427 28.7244 118.287 28.5727 118.156 28.4137C117.705 27.867 117.428 27.2205 117.359 27.036C117.358 27.0352 117.358 27.0344 117.359 27.0336C117.247 26.7131 117.012 25.9432 117.044 25.1975C117.101 23.882 117.566 23.0745 117.689 22.8722C118.015 22.3205 118.439 21.827 118.943 21.4134C119.387 21.0566 119.89 20.7727 120.432 20.5733C121.017 20.3393 121.645 20.2138 122.28 20.2037V22.3846C122.28 22.3846 121.118 22.0183 120.188 22.6669C119.538 23.1467 119.193 23.6148 119.092 24.4509C119.088 25.0662 119.246 25.9368 120.115 26.5175C120.216 26.5813 120.315 26.638 120.413 26.6875C120.565 26.8836 120.749 27.0545 120.96 27.1933C121.808 27.7272 122.519 27.7645 123.428 27.4177C124.035 27.1859 124.491 26.6633 124.703 26.0843C124.836 25.7228 124.834 25.3588 124.834 24.9825V14.2764H126.95C127.038 14.7707 127.28 15.4743 127.919 16.1713C128.176 16.4344 128.465 16.6671 128.78 16.8642C128.873 16.96 129.35 17.4334 129.961 17.7241C130.277 17.8743 130.61 17.9895 130.954 18.0672Z"
      fill="black"
    />
    <path
      d="M116.518 26.3662V26.3679L116.57 26.5094C116.564 26.4929 116.544 26.4428 116.518 26.3662Z"
      fill="#69C9D0"
    />
    <path
      d="M120.433 20.5732C119.891 20.7726 119.387 21.0565 118.943 21.4133C118.44 21.8278 118.016 22.3224 117.691 22.8751C117.568 23.0767 117.102 23.8848 117.045 25.2004C117.013 25.9461 117.248 26.716 117.36 27.0365C117.36 27.0373 117.36 27.0381 117.36 27.0389C117.43 27.2217 117.706 27.8682 118.157 28.4166C118.288 28.5756 118.428 28.7273 118.578 28.871C118.1 28.5559 117.673 28.1749 117.312 27.7405C116.865 27.1985 116.589 26.5587 116.517 26.3698C116.517 26.3685 116.517 26.3671 116.517 26.3658V26.3635C116.405 26.0432 116.17 25.273 116.202 24.5263C116.259 23.2108 116.725 22.4033 116.848 22.201C117.173 21.6482 117.597 21.1536 118.101 20.7392C118.544 20.3823 119.048 20.0983 119.59 19.8991C119.928 19.7655 120.28 19.6669 120.64 19.6051C121.183 19.5148 121.737 19.507 122.282 19.5819V20.2036C121.647 20.2135 121.019 20.3389 120.433 20.5732Z"
      fill="#69C9D0"
    />
    <path
      d="M126.953 14.2762H124.837V24.9827C124.837 25.359 124.837 25.7219 124.705 26.0845C124.492 26.6632 124.037 27.1857 123.431 27.4176C122.521 27.7657 121.81 27.7271 120.962 27.1931C120.752 27.055 120.566 26.8847 120.414 26.689C121.137 27.0563 121.784 27.0499 122.585 26.7435C123.19 26.5116 123.646 25.9891 123.859 25.4101C123.992 25.0485 123.99 24.6846 123.99 24.3086V13.6001H126.912C126.912 13.6001 126.88 13.8662 126.953 14.2762ZM130.954 17.4712V18.067C130.611 17.9892 130.279 17.8741 129.963 17.7239C129.352 17.4333 128.876 16.9598 128.783 16.864C128.891 16.9316 129.003 16.9931 129.119 17.0482C129.862 17.4017 130.594 17.5072 130.954 17.4712Z"
      fill="#69C9D0"
    />
    <defs>
      <linearGradient
        id="paint0_linear_490_9206"
        x1="90.0219"
        y1="14.6626"
        x2="90.0219"
        y2="29.3026"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EA8928" />
        <stop offset="1" stop-color="#CF2B8F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_490_9206"
        x1="90.0233"
        y1="14.6627"
        x2="90.0233"
        y2="29.3027"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EA8928" />
        <stop offset="1" stop-color="#CF2B8F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_490_9206"
        x1="85.7402"
        y1="14.6626"
        x2="85.7402"
        y2="29.3025"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EA8928" />
        <stop offset="1" stop-color="#CF2B8F" />
      </linearGradient>
    </defs>
  </svg>
);

export const DragIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="6" r="1.5" fill="black" />
    <circle cx="15" cy="6" r="1.5" fill="black" />
    <circle cx="9" cy="12" r="1.5" fill="black" />
    <circle cx="15" cy="12" r="1.5" fill="black" />
    <circle cx="9" cy="18" r="1.5" fill="black" />
    <circle cx="15" cy="18" r="1.5" fill="black" />
  </svg>
);
