import { alpha, styled } from "@mui/material";
import { DataGrid, DataGridProps, gridClasses } from "@mui/x-data-grid";
import React from "react";

const StripedDataGrid = (props: DataGridProps) => {
  const ODD_OPACITY = 0.2;
  const paginationModel = { page: 0, pageSize: 10 };

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: "#FBFBFC",
      "&.Mui-selected": {
        "&:hover": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  return (
    <StripedDataGrid
      {...props}
      slotProps={{
        cell: {
          className: "!border !border-primary-blue-100",
        },
      }}
      pageSizeOptions={[10, 20]}
      sx={{
        border: 0,
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
      }}
      initialState={{ pagination: { paginationModel } }}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
      }
    />
  );
};

export default StripedDataGrid;
