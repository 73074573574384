export const HidePasswordIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.87554 2.87299C2.58265 2.5801 2.10777 2.5801 1.81488 2.87299C1.52199 3.16588 1.52199 3.64076 1.81488 3.93365L3.33354 5.45231C2.23669 6.34667 1.43721 7.30482 1.09175 7.74809C0.974625 7.89837 0.974625 8.1009 1.09175 8.25118C1.83029 9.19881 4.64383 12.4996 8.00192 12.4996C8.69784 12.4996 9.37038 12.3579 10.0059 12.1247L12.0679 14.1867C12.3608 14.4796 12.8357 14.4796 13.1286 14.1867C13.4215 13.8938 13.4215 13.4189 13.1286 13.126L2.87554 2.87299ZM8.7969 10.9157C8.53186 10.9704 8.26636 10.9996 8.00192 10.9996C6.80389 10.9996 5.58409 10.3997 4.48438 9.54594C3.8121 9.024 3.24775 8.45603 2.83803 7.99964C3.23057 7.56237 3.76507 7.02269 4.40042 6.51919L5.52682 7.64559C5.51043 7.76122 5.50195 7.87939 5.50195 7.99954C5.50195 9.38025 6.62124 10.4995 8.00195 10.4995C8.12211 10.4995 8.24028 10.4911 8.35591 10.4747L8.7969 10.9157ZM7.64804 5.5244L10.4771 8.35345C10.4935 8.23784 10.502 8.11968 10.502 7.99954C10.502 6.61883 9.38266 5.49954 8.00195 5.49954C7.88181 5.49954 7.76366 5.50802 7.64804 5.5244ZM13.1658 7.99964C12.7561 7.54324 12.1917 6.97527 11.5195 6.45333C10.4197 5.59954 9.19994 4.99963 8.00192 4.99964C7.73756 4.99964 7.47214 5.02885 7.20719 5.08355L5.99816 3.87452C6.63362 3.64136 7.30608 3.49963 8.00192 3.49963C11.36 3.49963 14.1735 6.80046 14.9121 7.74809C15.0292 7.89837 15.0292 8.1009 14.9121 8.25118C14.5666 8.69443 13.7672 9.6525 12.6705 10.5468L11.6036 9.47994C12.2389 8.97649 12.7733 8.43686 13.1658 7.99964Z"
      fill="#666666"
    />
  </svg>
);

export const ShowPasswordIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      opacity="0.4"
      d="M14.9823 11.9999C14.9823 13.6499 13.649 14.9833 11.999 14.9833C10.349 14.9833 9.01562 13.6499 9.01562 11.9999C9.01562 10.3499 10.349 9.0166 11.999 9.0166C13.649 9.0166 14.9823 10.3499 14.9823 11.9999Z"
      stroke="#666666"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.9999 18.8915C14.9415 18.8915 17.6832 17.1582 19.5915 14.1582C20.3415 12.9832 20.3415 11.0082 19.5915 9.83319C17.6832 6.83319 14.9415 5.09985 11.9999 5.09985C9.0582 5.09985 6.31654 6.83319 4.4082 9.83319C3.6582 11.0082 3.6582 12.9832 4.4082 14.1582C6.31654 17.1582 9.0582 18.8915 11.9999 18.8915Z"
      stroke="#666666"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const DoubleArrowIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 15.0335L10.1667 10.8669L6 6.7002M11.8333 15.0335L16 10.8669L11.8333 6.7002"
      stroke="#4D6697"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
