import axios from "axios";

const getToken = () => {
  return localStorage.getItem("AccessToken");
};

const axiosClient = axios.create({
  // baseURL: process.env.REACT_APP_API_BASE_URL,
  baseURL: "",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: getToken(),
  },
});

axiosClient.interceptors.request.use(
  function (config) {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(function (response) {
  return response;
});
export default axiosClient;
