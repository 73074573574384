import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import Sidebar from "./sidebar";
import MobileFilters from "./header/mobileFilters";

const MainLayout = () => {
  return (
    <Box className="flex">
      <Sidebar />
      {/* Main Content */}
      <Box className="min-h-screen flex-column flex-1 lg:ml-[280px]">
        <Header />
        <Box className="flex sm:hidden">
          <MobileFilters />
        </Box>
        <Box className="h-full bg-primary-blue-50 w-[100vw] lg:w-full p-[30px]">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
