import GeneratedReport from "../components/reports/generatedReportSection";
import ReportsDetailSection from "../components/reports/reportsDetailSection";
import MainLayout from "../layout";
import Campaigns from "../pages/Campaigns";
import ComingInFuture from "../pages/ComingInFuture";
import Dashboard from "../pages/Dashboard";
// import Dashboard from "../pages/Dashboard";
import ManageDataSource from "../pages/ManageDataSource";
import Profile from "../pages/Profile";
import Reports from "../pages/Reports";
import routeConstants from "../utils/routeConstants";
// import AuthGuard from "./AuthGaurds";

const MainRoutes = {
  path: "/",
  element: (
    // <AuthGuard> use '/dashboard' to access login page
    <MainLayout />
    // </AuthGuard>
  ),
  children: [
    {
      path: routeConstants.dashboard,
      element: <Dashboard />,
    },
    {
      path: routeConstants.myProfile,
      element: <Profile />,
    },
    {
      path: routeConstants.campaigns,
      element: <Campaigns />,
    },
    {
      path: routeConstants.manageDataSource,
      element: <ManageDataSource />,
    },
    {
      path: routeConstants.reports,
      element: <ReportsDetailSection />,
    },
    {
      path: routeConstants.generateReports,
      element: <Reports />,
    },
    {
      path: routeConstants.generatedReport,
      element: <GeneratedReport />,
    },
    {
      path: "*",
      element: <ComingInFuture />,
    },
  ],
};

export default MainRoutes;
