import { Box } from "@mui/material";

const ComingInFuture = () => {
  return (
    <Box className="flex-column justify-center text-center">
      <Box className="text-3xl uppercase">Coming in Future!</Box>
      <Box className="text-xl text-primary-blue-950 italic">Stay Tuned</Box>
    </Box>
  );
};

export default ComingInFuture;
