import { Box, Button, Typography } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { CustomBlueDeleteIcon } from "../Icons";
import CustomTextField from "../formControls/CustomTextField";
import { RendererType } from "../../types/enums/enums";
import { ProfileFormFieldTypes } from "../../types/profileTypes";
import { useRef, useState } from "react";

const ProfileForm = () => {
  const profileDetailForm = useForm<ProfileFormFieldTypes>();
  const [removeProfile, setRemoveProfile] = useState<boolean>(false);
  const inputFile = useRef<HTMLInputElement | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = profileDetailForm;

  const onSubmit: SubmitHandler<ProfileFormFieldTypes> = (formData) => {
    console.log("Submitted Data : ", formData);
  };

  const onButtonClick = () => {
    inputFile.current && inputFile.current.click();
  };

  const imageUrl = removeProfile
    ? "https://placehold.co/10x10"
    : require("../../assets/profilePic.png");

  console.log("imageUrl>>", imageUrl);
  return (
    <>
      <Box className="flex-column gap-[30px]">
        <Typography className="!text-base !font-medium !text-primary-black-950">
          Profile Picture
        </Typography>
        <Box className="flex-column md:flex-row gap-10 items-center">
          <img
            src={imageUrl}
            alt="Profile-pic"
            width={130}
            height={130}
            className="rounded-full"
          />
          <Box className="flex gap-[15px] items-center">
            <input
              type="file"
              accept="image/png"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
            />

            <Button
              className="!px-5 !py- !rounded-xl !border !border-primary-blue-950 !text-primary-blue-950 !capitalize"
              variant="outlined"
              onClick={onButtonClick}
            >
              <Typography className="!text-base !font-medium">
                Change
              </Typography>
            </Button>
            <Box
              onClick={() => setRemoveProfile(true)}
              className="cursor-pointer rounded-xl p-[10px] border border-primary-blue-950 bg-primary-blue-50 flex-center"
            >
              <CustomBlueDeleteIcon />
            </Box>
          </Box>
        </Box>
      </Box>
      <form className="flex-column gap-7">
        <Box className="gap-5 grid md:grid-cols-2">
          <CustomTextField
            label="First Name"
            name="first_name"
            required
            placeholder="Enter First Name"
            type={RendererType.string}
            control={control}
            errors={errors.first_name?.message?.toString()}
            rules={{
              required: "First Name is Required",
            }}
          />
          <CustomTextField
            label="Last Name"
            name="last_name"
            placeholder="Enter Last Name"
            required
            type={RendererType.string}
            control={control}
            errors={errors.last_name?.message?.toString()}
            rules={{
              required: "Last Name is Required",
            }}
          />

          <CustomTextField
            label="Phone Number"
            name="phone_number"
            placeholder="Enter Phone Number"
            required
            type={RendererType.number}
            control={control}
            errors={errors.phone_number?.message?.toString()}
            rules={{
              required: "Phone Number is Required",
            }}
          />
          <CustomTextField
            label="Email"
            name="email"
            placeholder="Enter Email"
            required
            type={RendererType.string}
            control={control}
            errors={errors.email?.message?.toString()}
            rules={{
              required: "Email is Required",
            }}
          />
        </Box>
        <Box>
          <Button
            onClick={handleSubmit(onSubmit)}
            className="w-full md:w-0 !px-5 !py-2 !capitalize !bg-primary-blue-950 !text-white !rounded-lg"
          >
            Save
          </Button>
        </Box>
      </form>
    </>
  );
};

export default ProfileForm;
