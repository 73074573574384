import "react-international-phone/style.css";
import {
  BaseTextFieldProps,
  InputAdornment,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";

export interface MUIPhoneProps extends BaseTextFieldProps {
  value: string;
  onChange: (phone: string) => void;
}

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "hasIcon" && prop !== "length",
})<{ hasIcon?: boolean; length?: string }>(({ hasIcon, length }) => ({
  width: length || "100%",
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #E5E5E5 !important",
    borderRadius: "12px",
    "&.Mui-focused, &:hover": {
      border: "1px solid #E5E5E5",
      background: "#fff",
    },
    color: "#21232C !important",
  },
  "& .Mui-error": {
    marginLeft: "0px",
    "& .MuiFilledInput-input": {
      border: "1px solid #C82627",
      borderRadius: "4px",
    },
  },
}));

export const MuiPhone: React.FC<MUIPhoneProps> = ({
  value,
  onChange,
  ...restProps
}) => {
  const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "us",
      value,
      countries: defaultCountries,
      onChange: (data: any) => {
        onChange(data.phone);
      },
    });

  return (
    <StyledTextField
      variant="outlined"
      label=""
      color="primary"
      placeholder="Enter Phone Number"
      value={phone}
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginRight: "2px", marginLeft: "-8px" }}
          >
            <Select
              MenuProps={{
                style: {
                  height: "300px",
                  width: "360px",
                  top: "10px",
                  left: "-34px",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              sx={{
                width: "max-content",
                // Remove default outline (display only on focus)
                fieldset: {
                  display: "none",
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: "block",
                  },
                },
                // Update default spacing
                ".MuiSelect-select": {
                  padding: "8px",
                  paddingRight: "24px !important",
                },
                svg: {
                  right: 0,
                },
              }}
              value={country}
              onChange={(e) => setCountry(e.target.value as CountryIso2)}
              renderValue={(value) => (
                <FlagImage
                  iso2={country.iso2}
                  style={{ display: "flex" }}
                  width={25}
                />
              )}
            >
              {defaultCountries.map((c: any) => {
                const country = parseCountry(c);
                return (
                  <MenuItem key={country.iso2} value={country.iso2}>
                    <FlagImage
                      iso2={country.iso2}
                      style={{ marginRight: "8px" }}
                      width={25}
                    />
                    <Typography marginRight="8px">{country.name}</Typography>
                    <Typography color="gray">+{country.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};
