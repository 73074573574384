import React from "react";
import SocialsHeader from "../../common/SocialsHeader";
import InsightCardsSection from "../InsightCardsSection";
import { Box } from "@mui/material";

const InsightsSection = () => {
  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100">
      <SocialsHeader />
      <InsightCardsSection />
    </Box>
  );
};

export default InsightsSection;
