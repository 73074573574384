import React, { useState } from "react";
import ReportStepperWrapper from "../components/reports/reportStepper";
import ReportNameSection from "../components/reports/reportNameSection";
import SaveAndGenerateSection from "../components/reports/saveAndGenerateSection";
import { FormProvider, useForm } from "react-hook-form";
import { ReportType } from "../types/reportTypes";
import routeConstants from "../utils/routeConstants";
import { useNavigate } from "react-router-dom";

const Reports = () => {
  const navigate = useNavigate();
  const [section, setSection] = useState<number>(0);
  const [stepper, setStepper] = useState<number>(0);
  const reportStepperForm = useForm<ReportType>();

  const handleNextSection = () => {
    setSection(section + 1);
  };

  const handlePreviousSection = () => {
    setSection(section - 1);
  };

  const handleSection = (section: number) => {
    switch (section) {
      case 0:
        return (
          <FormProvider {...reportStepperForm}>
            <ReportStepperWrapper
              stepper={stepper}
              setStepper={setStepper}
              reportStepperForm={reportStepperForm}
              handleNextSection={handleNextSection}
              handlePreviousSection={handlePreviousSection}
            />
          </FormProvider>
        );
      case 1:
        return (
          <SaveAndGenerateSection
            setStepper={setStepper}
            reportStepperForm={reportStepperForm}
            handleNextSection={handleNextSection}
            handlePreviousSection={handlePreviousSection}
          />
        );
      case 2:
        return (
          <ReportNameSection
            reportStepperForm={reportStepperForm}
            handleNextSection={handleNextSection}
            handlePreviousSection={handlePreviousSection}
          />
        );
      case 3:
        navigate(routeConstants.reports);
    }
  };
  return <>{handleSection(section)}</>;
};

export default Reports;
