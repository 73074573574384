import { Box, Typography } from "@mui/material";
import { ExportIcon } from "../../Icons";
import StripedDataGrid from "../../common/StripedDataGrid";
import { columns } from "./GeneratedReportTableColumnHeaders";
import { generatedReportsRows } from "../../../utils/data";

const GeneratedReport = () => {
  return (
    <Box className="flex justify-center relative">
      <Box className="w-full flex-column bg-white  outline-none rounded-xl">
        {/* Header */}
        <Box className="flex-column p-5 py-2.5 items-start gap-[15px] border-b border-primary-black-50">
          <Box className="flex-between-center w-full">
            <Typography className="!text-xl !font-medium">
              Generated Report
            </Typography>

            <Box className="cursor-pointer rounded-md lg:rounded-xl px-[7px] py-[5px] lg:p-[10px] lg:px-5 gap-2.5 !bg-primary-blue-950 flex-center">
              <Typography className="!text-sm lg:!text-base !font-medium text-white">
                Export
              </Typography>
              {ExportIcon}
            </Box>
          </Box>
        </Box>

        {/* Table */}
        <Box className="grid grid-cols-1">
          <StripedDataGrid rows={generatedReportsRows} columns={columns} />
        </Box>
      </Box>
    </Box>
  );
};

export default GeneratedReport;
