import { Box, Button, Typography } from "@mui/material";
import CustomTextField from "../../components/formControls/CustomTextField";
import { RendererType } from "../../types/enums/enums";
import { SubmitHandler, useForm } from "react-hook-form";
type LoginFormFields = {
  email: string;
  password: string;
};

const Login = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<LoginFormFields>();

  const onSubmit: SubmitHandler<LoginFormFields> = (data) => {
    console.log("Submitted Data: ", data);
  };
  return (
    <Box className="login-container">
      <Box className="absolute top-0 left-0 bg-primary-blue-200 rounded-b-[80px] h-3/5 w-full">
        <Box className="py-[30px] px-[40px]">
          <img
            src={require("../../assets/logo.png")}
            alt="logo"
            className="sm:w-[240px] sm:h-[45px]"
          />
        </Box>
      </Box>
      <Box className="relative top-10 flex-column w-[80vw] sm:w-[60vw] rounded-[30px] bg-white z-10">
        <Box className="flex-between-center w-full p-[15px] sm:p-[30px] border-b border-gray-100">
          <Box className="flex-column gap-1.5">
            <Typography className="!text-[28px] sm:!text-[32px] !font-semibold">
              Login
            </Typography>
            <Typography className="!text-sm sm:!text-xl text-primary-black-600">
              Welcome back to TrialPartners!
            </Typography>
          </Box>
          <Button
            variant="outlined"
            className="!border w-[120px] sm:w-[190px] !border-primary-blue-950 sm:!px-2.5 sm:!py-4 sm:!text-base !font-medium !text-primary-blue-950 !rounded-xl !normal-case"
          >
            Sign Up
          </Button>
        </Box>
        <Box className="flex-between-center w-full p-[15px] sm:p-[30px]">
          {/* Form */}
          <form className="flex-column gap-2.5 sm:gap-[30px] w-full">
            <Box className="flex-column gap-2 sm:gap-[15px]">
              <Box className="flex-column gap-2.5 sm:gap-[30px]">
                <Box className="">
                  <CustomTextField
                    label="Email"
                    name="email"
                    control={control}
                    type={RendererType.string}
                    placeholder="Enter Email"
                    errors={errors.email?.message?.toString()}
                    rules={{
                      required: "Email is Required",
                    }}
                    required
                  />
                </Box>
                <Box className="flex-column ">
                  <Box>
                    <CustomTextField
                      label="Password"
                      name="password"
                      control={control}
                      type={RendererType.password}
                      placeholder="Enter Password"
                      errors={errors.password?.message?.toString()}
                      rules={{
                        required: "Password is Required",
                      }}
                      required
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="flex justify-end">
                <Typography className="font-bold sm:!font-semibold !text-sm sm:text-base cursor-pointer">
                  Forgot Password?
                </Typography>
              </Box>
            </Box>

            <Box>
              <Button
                className="!border w-full sm:w-[252px] !bg-primary-blue-950 !gap-2.5 sm:!px-2.5 sm:!py-4 !text-base !font-medium !text-white !rounded-xl !normal-case"
                variant="contained"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Login
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
