import SavedReports from "./savedReports";
import TopRecommendedReports from "./topRecommendedReports";

const ReportsDetailSection = () => {
  return (
    <div className="flex-column gap-5 items-center ">
      <SavedReports />
      <TopRecommendedReports />
    </div>
  );
};

export default ReportsDetailSection;
