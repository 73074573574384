import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { BellIcon, CalenderTimeIcon, PlusIcon } from "../../components/Icons";
import BasicSelect from "../../components/formControls/Select";
import { useDispatch } from "react-redux";
import { DrawerReducerAction } from "../../store/drawer/slice";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import routeConstants from "../../utils/routeConstants";

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const openDrawer = () => {
    dispatch(DrawerReducerAction.setDrawerOpen(true));
  };

  const getCurrentTitle = () => {
    switch (location.pathname) {
      case routeConstants.campaigns:
        return "Campaigns";
      case routeConstants.myProfile:
        return "My Profile";
      case routeConstants.manageDataSource:
        return "Manage Data Source";
      case routeConstants.reports:
        return "Reports";
      case routeConstants.conversions:
        return "Conversions";
      case routeConstants.adCopies:
        return "Ad Copies";
      case routeConstants.learnAboutUs:
        return "Learn About Us";
      case routeConstants.sendFeedback:
        return "Send Feedback";
      case routeConstants.aboutUs:
        return "About Us";
      case routeConstants.privacyPolicy:
        return "Privacy Policy";
      default:
        return "Dashboard";
    }
  };

  const CampaignOptions = [
    {
      title: "Campaign",
    },
    {
      title: "Conversions",
    },
    {
      title: "Reports",
    },
  ];

  const dateFilterOptions = [
    {
      title: "Last 30 days",
    },
    {
      title: "Last 15 days",
    },
    {
      title: "Last 7 days",
    },
  ];

  return (
    <Box className="header-container">
      <Box className="flex items-center gap-4">
        <Box onClick={openDrawer} className="cursor-pointer lg:hidden">
          <MenuIcon />
        </Box>
        <Typography className="!text-xl !font-medium">
          {getCurrentTitle()}
        </Typography>
      </Box>
      <Box className="flex gap-3 sm:hidden">
        <Tooltip
          title="Generate Reports"
          onClick={() => navigate(routeConstants.generateReports)}
        >
          <IconButton
            sx={{
              paddingX: "10px",
              paddingY: "8px",
              borderRadius: "6px",
              border: "1px solid #E9ECF2",
              gap: "10px",
              backgroundColor: "#F4F5F8",
            }}
          >
            {PlusIcon}
          </IconButton>
        </Tooltip>
        <Tooltip title="Notifications">
          <IconButton
            sx={{
              paddingX: "10px",
              paddingY: "8px",
              borderRadius: "6px",
              border: "1px solid #E9ECF2",
              gap: "10px",
              backgroundColor: "#F4F5F8",
            }}
          >
            {BellIcon}
          </IconButton>
        </Tooltip>
      </Box>
      <Box className="gap-3 hidden sm:flex">
        <Box className="">
          <BasicSelect options={CampaignOptions} />
        </Box>
        <Box onClick={() => navigate(routeConstants.generateReports)}>
          <IconButton
            sx={{
              paddingX: "12px",
              paddingY: "10px",
              borderRadius: "6px",
              border: "1px solid #E9ECF2",
              gap: "10px",
              backgroundColor: "#F4F5F8",
            }}
          >
            {PlusIcon}
            <Typography className="!text-sm !font-normal !text-primary-black-950 ">
              Generate report
            </Typography>
          </IconButton>
        </Box>
        <Box>
          <BasicSelect icon={CalenderTimeIcon} options={dateFilterOptions} />
        </Box>
        <Box>
          <IconButton
            sx={{
              paddingX: "12px",
              paddingY: "10px",
              borderRadius: "6px",
              border: "1px solid #E9ECF2",
              gap: "10px",
              backgroundColor: "#F4F5F8",
            }}
          >
            {BellIcon}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
