import { Box } from "@mui/material";
import React, { useState } from "react";

import CustomTabs from "../components/common/CustomTabs";
import ProfileForm from "../components/profile/ProfileForm";

const GetCurrentTabComponent = (activeTabIndex: number) => {
  switch (activeTabIndex) {
    case 0:
      return <ProfileForm />;
    case 1:
      return <p>Reset Password</p>;
    case 2:
      return <p>Payment History</p>;
    case 3:
      return <p>Plan & Billing</p>;
  }
};

const Profile = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Box className="rounded-[30px] md:mx-[45px] md:mt-8 p-[30px] bg-white flex-column gap-7">
      <CustomTabs setActiveTab={setActiveTab} activeTab={activeTab} />

      {GetCurrentTabComponent(activeTab)}
    </Box>
  );
};

export default Profile;
