import React from "react";
import { Box, Typography } from "@mui/material";

interface FormFieldLabelProps {
  label: string;
  required?: boolean;
}

const FormFieldLabel: React.FC<FormFieldLabelProps> = ({
  label,
  required = false,
}) => {
  return (
    <Box>
      <Typography
        variant="subtitle2"
        className="!text-primary-black-600 !text-sm sm:!text-base"
      >
        {label} {required && <span className="text-red">*</span>}
      </Typography>
    </Box>
  );
};

export default FormFieldLabel;
