import React, { useState } from "react";
import { Box, Button, Checkbox, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AddMetricsModalProps } from "../../types/dashboardTypes";
import CustomTextField from "../formControls/CustomTextField";
import { useForm } from "react-hook-form";
import { RendererType } from "../../types/enums/enums";
import { GridSearchIcon } from "@mui/x-data-grid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DragIcon } from "../Icons";
import { metricsData } from "../../utils/data";

const AddMetricsModal = ({
  isAddMetricsModalOpen,
  handleModalClose,
}: AddMetricsModalProps) => {
  const addMetricsForm = useForm();
  const [items, setItems] = useState<string[]>([]);

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setItems(reorderedItems);
  };

  const handleCheckboxChange = (metric: string, checked: boolean) => {
    if (checked) {
      setItems((prevItems) => [...prevItems, metric]);
    } else {
      setItems((prevItems) => prevItems.filter((item) => item !== metric));
    }
  };

  return (
    <Modal
      open={isAddMetricsModalOpen}
      onClose={handleModalClose}
      className="flex-center"
    >
      <Box className="w-[360px] lg:w-[1050px] h-full overflow-scroll lg:h-auto lg:overflow-auto flex-column bg-white outline-none rounded-xl">
        <Box className="p-5 flex-between-center">
          <Box className="flex-column gap-4">
            <Typography className="!text-xl !font-medium">
              Add Metrics
            </Typography>
          </Box>
          <Box
            className="w-[40px] h-[40px] rounded-lg bg-primary-blue-50 flex-center"
            onClick={handleModalClose}
          >
            <CloseIcon
              fontSize="small"
              className="text-primary-black-950 cursor-pointer"
            />
          </Box>
        </Box>
        <Box className="grid grid-cols-1 lg:grid-cols-2 border-t border-t-primary-blue-100">
          <Box className="flex-column p-5 gap-4 border-r border-r-primary-blue-100">
            <Typography className="!text-xl !font-medium">KPIs</Typography>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="p-[10px] bg-primary-blue-50 rounded-lg h-full"
                  >
                    {items.map((item, index) => (
                      <Draggable key={item} draggableId={item} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                            }}
                            className="select-none flex gap-2 items-center w-min text-nowrap pl-2 pr-4 py-2 my-2.5 bg-white rounded-md border border-primary-blue-100"
                          >
                            <DragIcon />
                            <Box
                              onClick={() =>
                                setItems((prev) =>
                                  prev.filter((i) => i !== item)
                                )
                              }
                              className="w-[24px] cursor-pointer h-[24px] flex-center bg-primary-blue-50 border border-primary-blue-100 rounded-full"
                            >
                              <CloseIcon fontSize="small" />
                            </Box>
                            <p>{item}</p>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <Box className="flex p-5 flex-col gap-4">
            <Typography className="!text-xl !font-medium">
              Search KPIs
            </Typography>
            <CustomTextField
              label=""
              name="search"
              icon={<GridSearchIcon />}
              placeholder="Search"
              type={RendererType.string}
              control={addMetricsForm.control}
            />
            <Box className="bg-primary-blue-50 p-[15px] rounded-lg h-[340px] overflow-y-scroll">
              {metricsData.map((category, index) => (
                <Box key={index} style={{ marginBottom: "20px" }}>
                  {Object.entries(category).map(([categoryName, metrics]) => (
                    <Box key={categoryName} className="flex-column gap-6">
                      <Typography className="!text-base !font-medium">
                        {categoryName}
                      </Typography>
                      <Box className="flex-column gap-2">
                        {metrics.map((metric: string, idx: number) => (
                          <Box
                            key={idx}
                            className="w-min text-nowrap flex items-center pr-1.5 rounded-[10px] border border-primary-blue-100 bg-[#FFFFFF]"
                          >
                            <Checkbox
                              id={metric}
                              checked={items.includes(metric)}
                              onChange={(e) =>
                                handleCheckboxChange(metric, e.target.checked)
                              }
                            />
                            <label htmlFor={metric}>{metric}</label>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box className="p-5 border-t border-t-primary-blue-100">
          <Button className="!px-[15px] flex items-center !gap-1.5 !bg-primary-blue-950 !border !border-primary-blue-200 !rounded-[10px] !capitalize !text-white">
            Apply
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddMetricsModal;
