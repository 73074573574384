import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  drawerOpen: false,
};

const drawer = createSlice({
  name: "drawerState",
  initialState,
  reducers: {
    setDrawerOpen(state, action) {
      state.drawerOpen = action.payload;
    },
  },
});

export const {
  actions: DrawerReducerAction,
  name: DrawerReducerName,
  reducer: DrawerReducer,
} = drawer;
