import { Box, Divider, LinearProgress, Typography } from "@mui/material";
import { adVisitorDeviceData } from "../../../../utils/data";

const AdVisitorDevices = () => {
  return (
    <Box className="w-[325px] md:w-full p-5 flex-column gap-[30px] border-t border-t-primary-blue-100">
      <Box className="flex gap-[30px] flex-col lg:flex-row">
        {adVisitorDeviceData.map((device, index) => (
          <Box className="flex gap-[30px]">
            <Box key={device.name} className="flex gap-2.5 items-center">
              {device.icon()}
              <Typography className="!text-base !text-primary-black-600">
                {device.name}
              </Typography>
            </Box>
            {index !== 2 && <Divider orientation="vertical" className="w-1" />}
          </Box>
        ))}
      </Box>
      <Box className="flex-column gap-5">
        {adVisitorDeviceData.map((device) => (
          <Box key={device.name} className="flex-column gap-2.5">
            <LinearProgress
              sx={{
                "& .MuiLinearProgress-bar": {
                  borderRadius: "10px",
                  backgroundColor: device.color,
                },
              }}
              variant="determinate"
              value={device.percentage}
              className="!h-[14px] !bg-primary-blue-50 !rounded-md !rounded-l-none"
            />
            <Box className="flex gap-2.5 items-center">
              {device.icon()}
              <Typography className="!text-base !font-semibold">
                {device.percentage}%
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AdVisitorDevices;
