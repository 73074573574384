import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { GalleryIcon, RedirectIcon } from "../Icons";
import { useState } from "react";
import AdInsightModal from "../dashboard/AdInsightModal";
import { adCopiesAccordionData, adCopiesInsightData } from "../../utils/data";
import { DoubleArrowIcon } from "../../assets/CustomIcons";

const AdCopiesCard = () => {
  const [isAdInsightModalOpen, setIsAdInsightModalOpen] =
    useState<boolean>(false);

  const handleModalClose = () => setIsAdInsightModalOpen(false);

  return (
    <Box className="flex-column p-4 gap-5 rounded-xl border border-primary-blue-100 bg-white">
      <Box className="flex-between-center">
        <Typography className="!text-xl !font-medium">
          VID - activewearinaction
        </Typography>
        <RedirectIcon />
      </Box>
      <Box className="relative" onClick={() => setIsAdInsightModalOpen(true)}>
        <img
          src={require("../../assets/adSample.png")}
          alt="thumbnail"
          className="w-[330px] h-[398px] rounded-[10px]"
        />
        <Box
          sx={{ background: "rgba(0, 0, 0, 0.40)" }}
          className="z-0 lg:z-10 flex-center cursor-pointer p-[7px] rounded absolute bottom-[15px] right-[15px]"
        >
          <GalleryIcon />
        </Box>
      </Box>
      <Box>
        {adCopiesAccordionData.map(({ name, icon, description }) => (
          <Accordion className="!shadow-none !pt-[15px] !pb-2.5">
            <AccordionSummary
              className="!p-0"
              expandIcon={
                <Box className="w-[34px] h-[34px] rounded-full border border-primary-blue-100 flex-center">
                  <ExpandLessIcon fontSize="medium" />
                </Box>
              }
            >
              <Box className="flex gap-2.5 items-center">
                {icon()}
                <Typography className="!text-lg !font-medium !leading-6 !text-primary-blue-950">
                  {name}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails className="!p-0 flex flex-col gap-2">
              {/* <Typography>{description}</Typography> */}
              <Box className="flex flex-col !gap-5">
                <Box className="flex justify-between items-center w-full">
                  <Typography className="!text-primary-black-600 !text-base">
                    Conversions
                  </Typography>
                  <Box className="!px-2 !bg-primary-blue-50 !border !border-primary-blue-200 !rounded-[5px] !text-lg !text-primary-blue-950 font-medium">
                    17,256
                  </Box>
                </Box>
                <Box className="flex justify-between items-center w-full">
                  <Typography className="!text-primary-black-600 !text-base">
                    Spend
                  </Typography>
                  <Box className="!px-2 !bg-primary-blue-50 !border !border-primary-blue-200 !rounded-[5px] !text-lg !text-primary-blue-950 font-medium">
                    $10.5k
                  </Box>
                </Box>
              </Box>
              <Box className="flex gap-2 items-center">
                <Typography className="!text-[14px] cursor-pointer !font-medium !underline !text-primary-blue-800">
                  See All Results
                </Typography>
                <DoubleArrowIcon />
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <AdInsightModal
        handleModalClose={handleModalClose}
        isAdInsightModalOpen={isAdInsightModalOpen}
        insightData={adCopiesInsightData}
      />
    </Box>
  );
};

export default AdCopiesCard;
