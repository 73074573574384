import SectionHeader from "../SectionHeader";
import { Box } from "@mui/material";
import AdCopiesCard from "../../common/AdCopiesCard";

const AdCopiesSection = () => {
  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100">
      <SectionHeader title="Ad Copies - Top Creative" withoutInfo />
      <Box className="p-5 border-t border-t-primary-blue-100 flex-column gap-5 lg:grid-cols-4 md:grid md:grid-cols-2">
        <AdCopiesCard />
        <AdCopiesCard />
        <AdCopiesCard />
        <AdCopiesCard />
      </Box>
    </Box>
  );
};

export default AdCopiesSection;
