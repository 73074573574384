import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";

export const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 50,
    headerAlign: "center",
    cellClassName: "!text-center",
    headerClassName: "table-header",
  },
  {
    field: "platform",
    headerName: "Platform",
    headerClassName: "table-header",
    resizable: false,
    editable: false,
    width: 130,
  },
  {
    field: "campaign",
    headerName: "Campaign",
    minWidth: 500,
    resizable: false,
    editable: false,
    renderCell: (item) => (
      <Box className={"flex gap-2 items-center"}>
        <img src={require("../../../assets/sampleImage.png")} alt="random" />
        <p>{item.value}</p>
      </Box>
    ),
    headerClassName: "table-header",
  },
  {
    field: "clicks",
    headerName: "Clicks",
    headerAlign: "left",
    type: "number",
    headerClassName: "table-header",
    cellClassName: "!text-left",
    width: 90,
  },
  {
    field: "impression",
    headerName: "Impression",
    headerAlign: "left",
    headerClassName: "table-header",
    width: 120,
    cellClassName: "!text-left",
    type: "number",
  },
  {
    field: "spend",
    headerName: "Spend",
    headerAlign: "left",
    headerClassName: "table-header !text-left",
    width: 90,
    cellClassName: "!text-left",
    type: "number",
  },
  {
    field: "conversions",
    headerName: "Conversions",
    headerAlign: "left",
    headerClassName: "table-header !text-left",
    width: 120,
    cellClassName: "!text-left",
    type: "number",
  },
  {
    field: "startDate",
    headerName: "Start Date",
    headerClassName: "table-header",
    width: 120,
    type: "date",
    valueFormatter: (value) => {
      if (value) {
        return format(value, "dd/mm/yyyy");
      }
      return "";
    },
  },
  {
    field: "endDate",
    headerName: "End Date",
    headerClassName: "table-header",
    width: 120,
    type: "date",
    valueFormatter: (value) => {
      if (value) {
        return format(value, "dd/mm/yyyy");
      }
      return "";
    },
  },
];
