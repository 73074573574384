import { Box, Typography } from "@mui/material";
import BasicSelect from "../formControls/Select";
import { campaignHeaderOptions } from "../../utils/data";

const CampaignHeader = () => {
  return (
    <Box>
      <Box className="p-5 py-2.5 flex-column sm:flex-row justify-between items-start gap-5 lg:gap-0 lg:items-center">
        <Typography className="!text-xl !font-medium !leading-6 !text-primary-black-950">
          All Campaigns
        </Typography>
        <Box className="flex gap-3 items-center">
          <Typography className="!text-base !font-medium !leading-6 !text-primary-black-950">
            Data Source
          </Typography>
          :
          <BasicSelect options={campaignHeaderOptions} />
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignHeader;
