import { Box, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import SectionHeader from "../../SectionHeader";
import { adSpentReturnData } from "../../../../utils/data";

const AdSpentReturn = () => {
  const renderLegend = () => {
    return (
      // <Box className="flex-column flex-center w-full md:flex-row gap-5 mt-6">
      <Box className="!grid !grid-cols-2 md:!grid-cols-4  w-full md:flex-row gap-5 mt-6">
        {adSpentReturnData.map((entry, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: entry.fill,
                borderRadius: "50%",
              }}
            />
            <Typography variant="body2">{entry.platform}</Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100 w-full">
      <SectionHeader title="Return on Ad Spend (Conversions on Ad Spend)" />
      <ResponsiveContainer
        minWidth={200}
        className="p-5 pl-0 !w-full !h-[500px] md:!h-[650px] xl:!h-[400px] border-t border-t-primary-blue-100"
      >
        <BarChart data={adSpentReturnData} barGap={20} barCategoryGap={40}>
          <CartesianGrid
            stroke="#E9ECF2"
            strokeDasharray="0"
            vertical={false}
          />
          <XAxis
            dataKey="platform"
            tickLine={false}
            axisLine={{ stroke: "#E9ECF2" }}
            className="text-xs text-primary-black-600"
            label={{
              value: "Platform",
              position: "insideBottom",
              offset: -20,
            }}
            tick={{ fill: "#666" }}
            stroke="#E9ECF2"
          />
          <YAxis
            domain={[0, 40]}
            ticks={[0, 10, 20, 30, 40]}
            tickLine={true}
            stroke="#E9ECF2"
            tick={{ fill: "#666" }}
            axisLine={{ stroke: "#E9ECF2" }}
            className="text-xs text-primary-black-600"
            label={{
              value: "ROAS",
              angle: -90,
              position: "insideLeft",
              offset: 10,
            }}
          />
          <Tooltip />
          <Legend content={renderLegend} />
          <Bar dataKey="ROAS" fill="#8884d8">
            {adSpentReturnData.map((entry, index) => (
              <Bar
                key={`bar-${index}`}
                fill={entry.fill}
                dataKey={entry.platform}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default AdSpentReturn;
