import { Box } from "@mui/material";
import AdSpentReturn from "./adSpentReturn";
import CostPerClick from "./costPerClick";

const ReturnAndCostSection = () => {
  return (
    <Box className="flex-column md:flex-row gap-5 w-full ">
      <AdSpentReturn />
      <CostPerClick />
    </Box>
  );
};

export default ReturnAndCostSection;
