import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { dataSourceList } from "../utils/data";

const ManageDataSource = () => {
  return (
    <Box className=" p-5 lg:px-[50px] lg:py-[30px] flex-column gap-[30px] bg-white rounded-[20px]">
      <Typography className="!text-base !text-primary-black-600">
        Connect the platform profiles with access to the ad account linked with
        TrailPartners. This ensures full data access and faster report loads.
      </Typography>
      <Box className="grid grid-cols-1 md:grid-cols-3 gap-10 lg:gap-[60px] items-center">
        {dataSourceList.map((item) => (
          <Box
            key={item.name}
            className="flex-column rounded-[20px] gap-[50px] items-center border border-primary-blue-100 py-10 px-20 lg:py-[60px] lg:px-[100px]"
          >
            <Box className="border-2 rounded-full border-primary-blue-100 flex-center w-[90px] h-[90px]">
              {item.icon()}
            </Box>
            <Typography className="!text-center !text-xl !font-medium !text-primary-black-950">
              {item.name}
            </Typography>
            <Box>
              <Button
                className={`!px-5 !py-2 !capitalize !rounded-lg !text-base !font-medium ${
                  item.isConnected
                    ? "!bg-primary-blue-100 !border !border-primary-blue-400 !text-primary-blue-950"
                    : "!bg-primary-blue-950 !text-white"
                }`}
              >
                {item.isConnected ? "Connected" : "Connect"}
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ManageDataSource;
