import React, { useState } from "react";
import { Controller, FieldValues } from "react-hook-form";
import { Box, Stack } from "@mui/material";
import FormFieldLabel from "./FormFieldLabel";
import { FieldConfig } from "../../types/forms";

// Utility function to format date from 'DD.MM.YYYY' to 'YYYY-MM-DD'
export const formatDate = (date: string) => {
  if (!date || typeof date !== "string") {
    return ""; // Return an empty string or handle it as necessary
  }
  return date.split(".").reverse().join("-");
};

// DatePicker component for rendering a date input field
const CustomDatePicker = <T extends FieldValues, U extends FieldValues = T>({
  label,
  name,
  type,
  editable = true,
  onChange,
  control,
  rules,
  errors,
  required,
  value,
}: FieldConfig<T, U>) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Controller
      name={name!}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rules={rules as any}
      render={({ field }) => {
        console.log("value>>", value);

        return (
          <Stack className="flex-column gap-2.5">
            {label && <FormFieldLabel label={label} required={required} />}
            <Box className="flex items-center relative w-full">
              <input
                className={`w-full custom-date-input border border-[#E5E5E5] rounded-xl py-4 px-5 text-sm font-normal outline-none !text-primary-black-600 ${
                  errors && "border-red1"
                }`}
                value={formatDate(value ? value : field.value)}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  onChange && onChange();
                }}
                disabled={!editable}
                placeholder="MM/DD/YYYY"
                type={isFocused ? "date" : "text"}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
            </Box>
            <span className="text-red1 mx-0 text-sm font-semibold">
              {errors}
            </span>
          </Stack>
        );
      }}
    />
  );
};

export default CustomDatePicker;
