import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import SectionHeader from "../SectionHeader";
import { Box, Typography } from "@mui/material";
import {
  costPerConversionData,
  costPerConversionSocialData,
} from "../../../utils/data";
import { StyledLineChart } from "../conversionsByDaySection";

const CostPerConversionSection = () => {
  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100">
      <SectionHeader title="Cost per Conversion" />
      <ResponsiveContainer
        minWidth={200}
        className="p-5 pl-0 !w-full !h-[400px] border-t border-t-primary-blue-100"
      >
        <StyledLineChart data={costPerConversionData}>
          <CartesianGrid
            stroke="#E9ECF2"
            strokeDasharray="0"
            vertical={false}
          />
          <XAxis
            dataKey="date"
            className="text-xs text-primary-black-600"
            label={{
              value: "September - 2024",
              position: "insideBottom",
              offset: -20,
            }}
            axisLine={{ stroke: "#E9ECF2" }}
            tick={{ fill: "#666" }}
            stroke="#E9ECF2"
            minTickGap={0}
          />
          <YAxis
            domain={[0, 80]}
            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80]}
            className="text-xs text-primary-black-600"
            tickLine={true}
            stroke="#E9ECF2"
            tick={{ fill: "#666" }}
            axisLine={{ stroke: "#E9ECF2" }}
            label={{
              value: "Conversion",
              angle: -90,
              position: "insideLeft",
              offset: 20,
              dy: 30,
            }}
          />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="Google"
            stroke="#F4B400"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="Instagram"
            stroke="#BA3478"
            strokeWidth={2}
          />
        </StyledLineChart>
      </ResponsiveContainer>
      <Box className="flex-column flex-center w-full md:flex-row gap-5 mt-6">
        {costPerConversionSocialData.map((item) => (
          <Box className="pb-5 flex gap-2.5 items-center">
            <Box
              sx={{ background: item.fill }}
              className={`w-3 h-3 rounded-full mr-2`}
            />
            <Typography className="!text-sm">{item.platform}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CostPerConversionSection;
