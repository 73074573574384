import { Box, Typography } from "@mui/material";
import React from "react";
import { InfoDarkIcon } from "../Icons";

type ReportHeaderProps = {
  title: string;
  subtitle?: string;
};

const ReportHeader = ({ title, subtitle }: ReportHeaderProps) => {
  return (
    <Box className="flex-column items-start gap-[15px] ">
      <Typography className="!text-xl !font-medium">{title}</Typography>
      {subtitle && (
        <Box className="flex justify-between gap-[5px]">
          <Box>{InfoDarkIcon}</Box>
          <Typography className="!text-sm !font-normal text-primary-black-600 flex items-center">
            {subtitle}
          </Typography>
        </Box>
      )}
      <span className="w-full border border-primary-blue-100" />
    </Box>
  );
};

export default ReportHeader;
