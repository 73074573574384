import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/auth/login";
import MainRoutes from "./MainRoutes";
import LoginWithTrialPartners from "../pages/auth/login-with-trialpartners";
// import GuestGuard from "./GuestGaurds";

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      // <GuestGuard> use '/login' to access login page
      <Login />
      // </GuestGuard>
    ),
  },
  {
    path: "/login-with-trialpartners",
    element: (
        // <GuestGuard> use '/login' to access login page
        <LoginWithTrialPartners />
        // </GuestGuard>
    ),
  },
  MainRoutes,
]);

export default router;
