import { Box, Typography } from "@mui/material";
import React from "react";
import SaveAndGenerateCardHeader from "../../common/SaveAndGenerateCardHeader";
import { CancelIcon } from "../../Icons";
import { UseFormReturn } from "react-hook-form";
import { ReportType } from "../../../types/reportTypes";

const SelectedGoogleCampaignCard = ({
  reportStepperForm,
}: {
  reportStepperForm: UseFormReturn<ReportType>;
}) => {
  const campaignList = reportStepperForm.watch("selectedCampaigns") ?? [];

  const handleItemCancel = (selectedCampaign: {
    name: string;
    img: string;
  }) => {
    reportStepperForm.setValue(
      "selectedCampaigns",
      campaignList.filter(
        (campaign: { name: string; img: string }) =>
          campaign.name !== selectedCampaign.name
      )
    );
  };
  return (
    <Box className="border p-[30px] border-primary-blue-100 rounded-[20px] flex-column gap-[30px]">
      {/* Header  */}
      <SaveAndGenerateCardHeader title="Selected Campaign" />

      <Box className="flex-column gap-[15px]">
        {campaignList.length > 0 ? (
          campaignList.map(
            (campaign: { name: string; img: string }, index: number) => (
              <Box
                key={index}
                className="flex-between-center w-full border rounded-xl p-5 border-primary-blue-100"
              >
                <Box className="flex items-center gap-5">
                  <Box
                    onClick={() => handleItemCancel(campaign)}
                    className="flex-center cursor-pointer !w-6 !h-6 !rounded-full !bg-primary-black-50"
                  >
                    {CancelIcon}
                  </Box>
                  <Box className="flex-column md:flex-row md:items-center md:gap-2.5">
                    <img
                      alt={campaign.name}
                      src={campaign.img}
                      height="54px"
                      width="54px"
                      className="rounded-lg"
                    />
                    <Typography>{campaign.name}</Typography>
                  </Box>
                </Box>
              </Box>
            )
          )
        ) : (
          <p className="text-center text-sm">No data found!</p>
        )}
      </Box>
    </Box>
  );
};

export default SelectedGoogleCampaignCard;
