import { createSlice } from "@reduxjs/toolkit";
import { ReportType } from "../../types/reportTypes";

type ReportsType = {
  reportList: ReportType[];
};

export const initialState: ReportsType = {
  reportList: [],
};

const report = createSlice({
  name: "reportState",
  initialState,
  reducers: {
    setReportList(state, action) {
      state.reportList = [...state.reportList, action.payload];
    },
  },
});

export const {
  actions: ReportReducerAction,
  name: ReportReducerName,
  reducer: ReportReducer,
} = report;
