import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { CampaignOptionType, ReportType } from "../../../../types/reportTypes";
import { reportsStepTwoCampaigns } from "../../../../utils/data";

const ReportsStepTwo = ({
  reportStepperForm,
}: {
  reportStepperForm: UseFormReturn<ReportType>;
}) => {
  const { watch, setValue } = reportStepperForm;

  const selectedCampaigns = watch("selectedCampaigns") || [];

  const handleCheckboxChange = (campaign: CampaignOptionType) => {
    const isSelected = selectedCampaigns.some(
      (selected: any) => selected.name === campaign.name
    );

    if (isSelected) {
      setValue(
        "selectedCampaigns",
        selectedCampaigns.filter(
          (selected: any) => selected.name !== campaign.name
        )
      );
    } else {
      setValue("selectedCampaigns", [...selectedCampaigns, campaign]);
    }
  };

  return (
    <Box className="flex-column gap-[15px]">
      {reportsStepTwoCampaigns.map((campaign, index) => (
        <Box
          key={index}
          className="flex-between-center w-full border rounded-xl p-5 border-primary-blue-100"
        >
          <Box className="flex items-center gap-2.5">
            <Checkbox
              checked={selectedCampaigns.some(
                (selected: CampaignOptionType) =>
                  selected.name === campaign.name
              )}
              onChange={() => handleCheckboxChange(campaign)}
            />
            <Box className="flex-column md:flex-row md:items-center md:gap-2.5">
              <img
                alt={campaign.name}
                src={campaign.img}
                height="54px"
                width="54px"
                className="rounded-lg"
              />
              <Typography>{campaign.name}</Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ReportsStepTwo;
