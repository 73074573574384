import React, { SyntheticEvent } from "react";
import { Tabs, Tab, Box } from "@mui/material";

const CustomTabs = ({
  setActiveTab,
  activeTab,
}: {
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  activeTab: number;
}) => {
  const handleTabChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setActiveTab(newValue);
  };

  const tabLabels = [
    "My Details",
    "Reset Password",
    "Payment History",
    "Plan & Billing",
  ];

  return (
    <>
      <Box className="w-[250px] md:w-full bg-primary-blue-50 border-2 border-primary-blue-100 rounded-lg overflow-hidden">
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          value={activeTab}
          onChange={handleTabChange}
          TabIndicatorProps={{ style: { display: "none" } }}
          sx={{
            "& .MuiTab-root": {
              textTransform: "none",
              color: "#333",
              fontSize: "16px",
              padding: "18px 40px",
              backgroundColor: "#F4F5F8",
              borderRadius: "0px",
              borderRight: "2px solid #E9ECF2",
              transition: "background-color 0.3s",
            },
            "& .Mui-selected": {
              fontWeight: 600,
              height: "100% !important",
              backgroundColor: "#fff",
              color: "black !important",
            },
            "& .MuiTabs-scrollButtons.Mui-disabled": {
              opacity: 0.3,
            },
          }}
        >
          {tabLabels.map((item, index) => (
            <Tab key={index} label={item} />
          ))}
        </Tabs>
      </Box>
    </>
  );
};

export default CustomTabs;
