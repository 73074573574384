import { Box } from "@mui/material";
import React from "react";
import { CustomChipProps } from "../../types/common";

const CustomChip = ({ label, icon, color }: CustomChipProps) => {
  const getChipStyles = () => {
    switch (color) {
      case "success":
        return "bg-chip-green-100 text-chip-green-300 border-chip-green-200";
      case "warning":
        return "bg-chip-yellow-100 text-chip-yellow-300 border-chip-yellow-200";
      case "info":
        return "bg-blue-100 text-blue-700 border-blue-300";
      case "error":
        return "bg-red-100 text-red-700 border-red-300";
      default:
        return "bg-gray-100 text-gray-700 border-gray-300";
    }
  };

  return (
    <Box
      className={`flex px-1.5 py-0.5 items-center gap-1.5 rounded-md border-2 ${getChipStyles()}`}
    >
      {icon && <Box>{icon}</Box>}
      <Box className="font-medium text-xs">{label}</Box>
    </Box>
  );
};

export default CustomChip;
