const routeConstants = {
  login: "/login",
  dashboard: "/",
  myProfile: "/profile",
  campaigns: "/campaigns",
  manageDataSource: "/manage-data-source",
  reports: "/reports",
  generateReports: "/generate-reports",
  generatedReport: "/reports/generated-report",
  conversions: "/conversions",
  adCopies: "/ad-copies",
  learnAboutUs: "/learn-about-us",
  sendFeedback: "/send-feedback",
  aboutUs: "/about-us",
  privacyPolicy: "/privacy-policy",
};

export default routeConstants;
