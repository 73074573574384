import { Box } from "@mui/material";
import CampaignHeader from "../components/campaign/CampaignHeader";
import StripedDataGrid from "../components/common/StripedDataGrid";
import { columns } from "../components/campaign/CampaignTableColumnHeaders";
import { generatedReportsRows } from "../utils/data";

const Campaigns = () => {
  return (
    <Box className="flex justify-center">
      <Box className="w-full flex-column bg-white  outline-none rounded-xl">
        {/* Header */}
        <CampaignHeader />

        {/* Table */}
        <Box className="grid grid-cols-1">
          <StripedDataGrid rows={generatedReportsRows} columns={columns} />
        </Box>
      </Box>
    </Box>
  );
};

export default Campaigns;
