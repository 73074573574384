import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { BasicSelectType, SelectOptionType } from "../../types/forms";
import { InputAdornment } from "@mui/material";
import { ArrowDownIcon } from "../Icons";

export default function BasicSelect({ options, icon }: BasicSelectType) {
  const [value, setValue] = React.useState(options[0]?.title ?? "");

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 136, height: "100%" }}>
      <FormControl fullWidth>
        <Select
          value={value}
          IconComponent={() => null}
          onChange={handleChange}
          displayEmpty
          inputProps={{
            "aria-label": "Without label",
            className: "p-0! !m-0",
          }}
          startAdornment={
            icon && (
              <InputAdornment className="!m-0" position="start">
                <Box>{icon()}</Box>
              </InputAdornment>
            )
          }
          endAdornment={
            <InputAdornment className="!m-0" position="end">
              <Box className="border-l-2 border-l-primary-blue-100 pl-2">
                {ArrowDownIcon}
              </Box>
            </InputAdornment>
          }
          sx={{
            cursor: "pointer",
            borderRadius: "6px",
            border: "1px solid #E9ECF2 ",
            backgroundColor: "#F4F5F8",
            "& .MuiSelect-select": {
              height: "100%",
            },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
            "& .MuiOutlinedInput-input": {
              paddingX: "12px !important",
              paddingY: "10px !important",
              backgroundColor: "#F4F5F8",
            },
          }}
        >
          {options?.map((item: SelectOptionType) => (
            <MenuItem value={item.title}>
              <div className="!flex !gap-2">
                {item?.icon ? item?.icon() : ""} {item?.title}
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
