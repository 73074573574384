import { Box, styled, Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import SectionHeader from "../SectionHeader";
import {
  conversionsByDayData,
  conversionsByDaySocialData,
} from "../../../utils/data";

export const StyledLineChart = styled(LineChart)(() => ({
  "& svg": {
    overflow: "visible",
  },
}));

const ConversionsByDaySection = () => {
  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100">
      <SectionHeader title="Conversions by Day" />
      <Box className="flex-column items-center bg-white p-6 rounded-lg shadow-md border-t border-t-primary-blue-100">
        <ResponsiveContainer minWidth={200} height={400}>
          <StyledLineChart data={conversionsByDayData}>
            <CartesianGrid
              stroke="#E9ECF2"
              strokeDasharray="0"
              vertical={false}
            />
            <XAxis
              dataKey="date"
              className="text-xs text-primary-black-600"
              label={{
                value: "September - 2024",
                position: "insideBottom",
                offset: -20,
              }}
              axisLine={{ stroke: "#E9ECF2" }}
              tick={{ fill: "#666" }}
              stroke="#E9ECF2"
              minTickGap={0}
            />
            <YAxis
              domain={[0, 80]}
              ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80]}
              tickLine={true}
              stroke="#E9ECF2"
              tick={{ fill: "#666" }}
              axisLine={{ stroke: "#E9ECF2" }}
              className="text-xs text-primary-black-600"
              label={{
                value: "Cost per Conversions",
                angle: -90,
                position: "insideLeft",
                offset: 10,
                dy: 55,
              }}
            />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="Google"
              stroke="#F4B400"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="Facebook"
              stroke="#4B73B8"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="Instagram"
              stroke="#BA3478"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="TikTok"
              stroke="#60C0C8"
              strokeWidth={2}
            />
          </StyledLineChart>
        </ResponsiveContainer>
        <Box className="flex-column md:flex-row gap-4 md:gap-0 justify-center md:space-x-8 mt-10">
          {conversionsByDaySocialData.map((item) => (
            <Box className="md:w-[157px] lg:w-[177px] flex gap-5 px-5 py-2.5 rounded-[10px] border border-primary-blue-100">
              <Box
                sx={{
                  background: item.fill,
                }}
                className={`w-3 h-max-full rounded-full mr-2`}
              />
              <Box className="flex-column gap-3">
                <Typography className="!text-xl font-bold">
                  {item.percent}%
                </Typography>
                <Typography className="!text-sm !text-primary-black-600">
                  {item.platform}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ConversionsByDaySection;
