import { Box, Drawer, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  ArrowRightIcon,
  NavigationButtonRectangle,
} from "../../components/Icons";
import { useDispatch, useSelector } from "react-redux";
import { DrawerReducerAction } from "../../store/drawer/slice";
import { drawerOpenSelector } from "../../store/drawer/selector";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import routeConstants from "../../utils/routeConstants";
import { sidebarItems } from "../../utils/data";

const Sidebar = () => {
  const dispatch = useDispatch();

  const isDrawerOpen = useSelector(drawerOpenSelector);

  return (
    <Box className="sidebar-container lg:fixed lg:bg-white no-scrollbar">
      <Drawer
        open={isDrawerOpen}
        onClose={() => dispatch(DrawerReducerAction.setDrawerOpen(false))}
        className="block lg:hidden"
      >
        <MenuItems />
      </Drawer>
      <Box>
        <MenuItems />
      </Box>
    </Box>
  );
};

const MenuItems = () => {
  const [selectedButton, setSelectedButton] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const handleButtonClick = (index: any) => {
    setSelectedButton(index);
  };

  useEffect(() => {
    const currentPage = location.pathname;
    const buttonIndex = sidebarItems.findIndex(
      (item) => item.link === currentPage
    );
    setSelectedButton(buttonIndex);
  }, [location.pathname]);

  return (
    <Box className="px-5 py-[25px] flex-column gap-10 no-scrollbar">
      <Box className="flex-center cursor-pointer">
        <img
          src={require("../../assets/logo.png")}
          alt="logo"
          className=""
          onClick={() => navigate(routeConstants.dashboard)}
        />
      </Box>
      <Box className="flex-column gap-[30px]">
        {/* my profile section */}
        <Box className="flex-column gap-2.5">
          <Typography className="!text-sm !font-normal !text-primary-black-600 uppercase">
            My Profile
          </Typography>
          <NavLink
            to={routeConstants.myProfile}
            onClick={() => setSelectedButton(-1)}
            className={`px-3 py-2.5 rounded-md border border-primary-blue-100 gap-2.5 bg-primary-blue-50 flex-between-center ${
              selectedButton === -1 && "border border-primary-blue-950"
            }`}
          >
            <Box className="flex gap-2 items-center">
              <img
                src={require("../../assets/profilePic.png")}
                alt="logo"
                className="w-7 h-7 rounded-full"
              />
              <Box className="flex-column items-start">
                <Typography className="!text-sm font-normal text-primary-black-950 ">
                  Ronald Richards
                </Typography>
                <Typography className="!text-xs font-normal text-primary-blue-800 ">
                  ronald12@gmail.com
                </Typography>
              </Box>
            </Box>
            {ArrowRightIcon}
          </NavLink>
        </Box>

        {/* Navigation Buttons */}
        <Box className="flex-column gap-2.5">
          {sidebarItems.map(({ icon, label, link }, index) => (
            <>
              {index === 8 && (
                <span
                  key="separator"
                  className="my-5 border border-[#E9ECF2]"
                />
              )}
              <Box className="flex">
                {selectedButton === index && NavigationButtonRectangle}
                <NavLink
                  key={index}
                  to={link}
                  onClick={() => handleButtonClick(index)}
                  className={`px-3 py-2.5 rounded-md gap-2.5 flex-1 justify-between cursor-pointer ${
                    selectedButton === index
                      ? "border border-[#E9ECF2] bg-[#F4F5F8]"
                      : "transparent"
                  }`}
                >
                  <Box className="flex gap-1.5 items-center">
                    {icon}
                    <Box className="flex-column items-start">
                      <Typography className="!text-sm font-normal text-primary-black-950 ">
                        {label}
                      </Typography>
                    </Box>
                  </Box>
                </NavLink>
              </Box>
            </>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
