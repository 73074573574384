import axiosClient from "../apiClient";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

export const UserService = async () => {
  try {
    return await axiosClient.get("https://dummyjson.com/users");
  } catch (error) {
    return error;
  }
};
