import { Box, Button, Typography } from "@mui/material";
import React from "react";
import routeConstants from "../../../../utils/routeConstants";
import { useNavigate } from "react-router-dom";
import { topRecommendedReportsRows } from "../../../../utils/data";

const TopRecommendedReports = () => {
  const navigate = useNavigate();

  return (
    <Box className="bg-white flex-column w-full lg:w-[80%] rounded-[20px] border border-primary-blue-100">
      {/* header */}
      <Box className="flex-between-center gap-5 p-5 border-b border-b-primary-blue-100">
        <Typography className="!text-xl !font-medium">
          Top Recommended Reports
        </Typography>
      </Box>

      <Box className="flex-column gap-[15px] p-5">
        {topRecommendedReportsRows.map((item, idx) => {
          const lastItem = topRecommendedReportsRows.length - 1;

          return (
            <>
              <Box className="flex-between-center">
                <Box className="flex-column md:flex-row gap-[5px]">
                  <Typography className="!text-balance">{item} </Typography>
                  <Typography className="text-primary-black-600">
                    - 12332
                  </Typography>
                </Box>
                <Box>
                  <Button
                    onClick={() => navigate(routeConstants.generatedReport)}
                    className="!py-2 !px-5 !text-base !text-primary-blue-950 !border !border-primary-blue-400 !rounded-lg !bg-primary-blue-100 !normal-case"
                  >
                    View
                  </Button>
                </Box>
              </Box>
              {idx === lastItem ? (
                <span />
              ) : (
                <span className="w-full border border-primary-blue-100" />
              )}
            </>
          );
        })}
      </Box>
    </Box>
  );
};

export default TopRecommendedReports;
