import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { reportListSelector } from "../../../../store/report/selector";
import { ReportType } from "../../../../types/reportTypes";

const SavedReports = () => {
  const reportList = useSelector(reportListSelector);

  return (
    <Box className="bg-white flex-column h-[400px] w-full lg:w-[80%] rounded-[20px] border border-primary-blue-100">
      {/* header */}
      <Box className="flex-between-center gap-5 p-5 border-b border-b-primary-blue-100 ">
        <Typography className="!text-xl !font-medium">Saved Reports</Typography>
      </Box>

      <Box className="flex-column gap-[15px] p-5">
        {reportList?.length > 0 ? (
          reportList?.map((item: ReportType, index: number) => {
            return (
              <>
                <Box key={index} className="flex-between-center">
                  <Box className="flex-column md:flex-row gap-[5px]">
                    <Typography>{item.report_name}</Typography>
                  </Box>
                  <Box>
                    <Button className="!py-2 !px-5 !text-base !text-primary-blue-950 !border !border-primary-blue-400 !rounded-lg !bg-primary-blue-100 !normal-case">
                      View
                    </Button>
                  </Box>
                </Box>
                <span className="border border-primary-blue-100" />
              </>
            );
          })
        ) : (
          <p className="text-center">
            No reports found! Please generate reports and save them to view them
            here.
          </p>
        )}
      </Box>
    </Box>
  );
};

export default SavedReports;
