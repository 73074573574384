import { Box } from "@mui/material";
import React from "react";
import CustomTextField from "../../formControls/CustomTextField";
import { RendererType } from "../../../types/enums/enums";
import { UseFormReturn } from "react-hook-form";
import SaveAndGenerateCardHeader from "../../common/SaveAndGenerateCardHeader";
import { ReportType } from "../../../types/reportTypes";

const SelectedDateRangeCard = ({
  reportStepperForm,
}: {
  reportStepperForm: UseFormReturn<ReportType>;
}) => {
  const {
    formState: { errors },
    control,
    watch,
  } = reportStepperForm;

  return (
    <Box className="border p-[30px] border-primary-blue-100 rounded-[20px] flex-column gap-[30px]">
      {/* Header  */}
      <SaveAndGenerateCardHeader title="Selected Date Range" />

      <Box className="flex-column md:flex-row gap-[25px]">
        <Box className="w-full">
          <CustomTextField
            label="Start Date"
            name="start_date"
            placeholder="MM/DD/YYYY"
            type={RendererType.date}
            control={control}
            errors={errors.start_date?.message?.toString()}
            rules={{
              required: "First Name is Required",
            }}
            value={watch("start_date")}
          />
        </Box>
        <Box className="w-full">
          <CustomTextField
            label="End Date"
            name="end_date"
            placeholder="MM/DD/YYYY"
            type={RendererType.date}
            control={control}
            errors={errors.end_date?.message?.toString()}
            rules={{
              required: "First Name is Required",
            }}
            value={watch("end_date")}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SelectedDateRangeCard;
