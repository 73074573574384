import { Box, Typography } from "@mui/material";
import SaveAndGenerateCardHeader from "../../common/SaveAndGenerateCardHeader";
import { CancelIcon } from "../../Icons";
import { UseFormReturn } from "react-hook-form";
import { ReportType } from "../../../types/reportTypes";

const SavePlatformCard = ({
  reportStepperForm,
}: {
  reportStepperForm: UseFormReturn<ReportType>;
}) => {
  const selectedOptionList = reportStepperForm.watch("selectedOptions") ?? [];

  const handleItemCancel = (item: any) => {
    reportStepperForm.setValue(
      "selectedOptions",
      selectedOptionList.filter((option: any) => option.name !== item.name)
    );
  };
  return (
    <Box className="border p-[30px] border-primary-blue-100 rounded-[20px] flex-column gap-[30px]">
      {/* Header  */}
      <SaveAndGenerateCardHeader title="Selected Platform" />
      {selectedOptionList.length > 0 ? (
        selectedOptionList.map((item: any) => (
          <Box className="flex-between-center w-full border rounded-xl p-5 border-primary-blue-100">
            <Box className="flex items-center gap-[15px]">
              <Box
                onClick={() => handleItemCancel(item)}
                className="flex-center cursor-pointer !w-6 !h-6 !rounded-full !bg-primary-black-50"
              >
                {CancelIcon}
              </Box>
              <Typography>{item.name}</Typography>
            </Box>
            {item.icon}
          </Box>
        ))
      ) : (
        <p className="text-center text-sm">No data found!</p>
      )}
    </Box>
  );
};

export default SavePlatformCard;
