import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { EditIcon } from "../Icons";

const SaveAndGenerateCardHeader = ({ title }: { title: string }) => {
  return (
    <Box className="flex-column items-start gap-[15px] ">
      <Box className="w-full flex justify-between gap-[5px]">
        <Typography className="!text-xl !font-medium">{title}</Typography>

        <Button className="!p-[10px] !text-base !text-primary-blue-950 !border !border-primary-blue-400 !rounded-[10px] !bg-primary-blue-100">
          <Box>{EditIcon}</Box>
        </Button>
      </Box>
      <span className="w-full border border-primary-blue-100" />
    </Box>
  );
};

export default SaveAndGenerateCardHeader;
