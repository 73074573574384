import { Box, Button, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AddWithBoxIcon, GalleryIcon } from "../Icons";
import { SocialIconGroup } from "../common/SocialsHeader";
import { AdInsightModalProps } from "../../types/dashboardTypes";
import AddMetricsModal from "./AddMetricsModal";
import { useState } from "react";

const AdInsightModal = ({
  isAdInsightModalOpen,
  handleModalClose,
  insightData,
}: AdInsightModalProps) => {
  const [isAddMetricsModalOpen, setIsAddMetricsModalOpen] =
    useState<boolean>(false);

  const handleMetricsModalClose = () => setIsAddMetricsModalOpen(false);

  return (
    <Modal
      open={isAdInsightModalOpen}
      onClose={handleModalClose}
      className="flex-center"
    >
      <Box className="w-[360px] lg:w-[480px] p-5 flex-column bg-white gap-5 outline-none rounded-xl">
        <Box className="flex justify-between">
          <Box className="flex-column gap-4">
            <Box className="flex gap-4 items-center">
              <Button className="!px-2.5 !py-1.5 !h-[36px] !rounded-md !bg-primary-black-950 !text-white !text-sm !font-medium">
                All Ads
              </Button>
              <SocialIconGroup />
            </Box>
            <Typography className="!text-xl !font-medium">
              VID - activewearinaction
            </Typography>
          </Box>
          <Box onClick={handleModalClose}>
            <CloseIcon
              fontSize="small"
              className="text-primary-black-950 cursor-pointer"
            />
          </Box>
        </Box>
        <Box className="relative">
          <img
            src="https://s3-alpha-sig.figma.com/img/74c6/49a2/53558d6675a00b3f6282aab3b890a9ca?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=IF0BBOUqA~KfRpw8CJZuezWNo4qww52UFEsr6JEsg9j8Ck7ZzrzDm27QshfyUCYY4F5ndHBXca-TZTQX1Lkr0RxEXMUlfBeXlXdCwYNvWwxdEk1Js7mYohNvST4NTakc-tZEfR4LlG5xJoss8Hi3hxrk9iDR7hf51D~jtSZpPKRkYGZjOHY1tXmCLOkr5FiehDAI-HIG8nCrxPsyPujooatzpKBv9v5F~owVJJwdXHi1nUmV-G9kezAv75flX6Xdtb~-z16r5~Ev8uuT2sqDQ3wkphgLY0nu9T6c6rFRjG45KZbT8EZC5HcAFZcJwoSNVNdszTMOvZzAASMSwSnwyg__"
            alt="thumbnail"
            className="w-[440px] h-[180px] rounded-[10px]"
          />
          <Box
            sx={{ background: "rgba(0, 0, 0, 0.40)" }}
            className="z-10 flex-center cursor-pointer p-[7px] rounded absolute bottom-[15px] right-[15px]"
          >
            <GalleryIcon />
          </Box>
        </Box>
        <Box className="flex-column">
          {insightData.map((item) => (
            <Box
              className="flex-between-center py-2.5 border-b border-b-primary-black-200 border-dashed"
              key={item.title}
            >
              <Typography className="!text-base !leading-7 !text-primary-black-600">
                {item.title}
              </Typography>
              <Typography className="!text-base !font-medium !text-primary-black-950">
                {item.amount}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box>
          <Button
            onClick={() => setIsAddMetricsModalOpen(true)}
            className="!px-[15px] !py-2.5 flex items-center !gap-1.5 !bg-primary-blue-100 !border !border-primary-blue-200 !rounded-[10px] !capitalize"
          >
            <AddWithBoxIcon />
            <Typography className="!text-base !text-primary-blue-950">
              Add Metrics
            </Typography>
          </Button>
        </Box>
        <AddMetricsModal
          isAddMetricsModalOpen={isAddMetricsModalOpen}
          handleModalClose={handleMetricsModalClose}
        />
      </Box>
    </Modal>
  );
};

export default AdInsightModal;
