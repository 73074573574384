import { LoginWithCodeParamType } from "../types/AuthTypes";
import axiosClient from "../apiClient";

export const TrailPartnersAuthService = async (params: LoginWithCodeParamType) => {
  try {
    const response = await axiosClient.post("https://ad-analytics.projectanddemoserver.com/api/callback/oauth", params);
    return response;
  } catch (error) {
    return error;
  }
};
