import { Box, Typography } from "@mui/material";
import React from "react";
import SectionHeader from "../../SectionHeader";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { costPerClickData } from "../../../../utils/data";

const CostPerClick = () => {
  const totalCost = costPerClickData.reduce((acc, curr) => acc + curr.cost, 0);

  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100 w-full">
      <SectionHeader title="Cost per Click" />
      <Box className="xl:grid grid-cols-2 p-5 items-center border-t border-t-primary-blue-100">
        <Box className="relative w-full h-[250px] mb-10 lg:mb-0">
          <ResponsiveContainer minWidth={200}>
            <PieChart>
              {/* Donut Chart */}
              <Pie
                data={costPerClickData}
                dataKey="cost"
                nameKey="platform"
                cx="50%"
                cy="50%"
                innerRadius={80}
                outerRadius={120}
                fill="#8884d8"
                labelLine={false}
              >
                {costPerClickData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.fill} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          {/* Total Cost in Center */}
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <Typography className="!text-md !text-primary-black-600">
              Total
            </Typography>
            <Typography className="!text-primary-black-950 !text-2xl !font-bold">
              ${totalCost.toFixed(2)}
            </Typography>
          </Box>
        </Box>

        <Box className="flex-column gap-3 p-5 rounded-[10px] border border-primary-blue-100">
          {costPerClickData.map((item, index) => (
            <Box
              className={`flex gap-[13px] ${
                index !== 3 && "pb-2 border-b border-b-primary-blue-100"
              }`}
            >
              <Box
                className={`w-3 h-3 rounded-full mt-1`}
                sx={{ background: item.fill }}
              />
              <Box className="flex-column gap-2.5">
                <Typography className="!text-md !leading-6 !text-primary-black-600">
                  {item.platform}
                </Typography>
                <Typography className="!text-xl !font-bold">
                  ${item.cost}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CostPerClick;
