import { Box, Typography } from "@mui/material";
import React from "react";
import { InfoIcon } from "../Icons";

const SectionHeader = ({
  title,
  withoutInfo = false,
}: {
  title: string;
  withoutInfo?: boolean;
}) => {
  return (
    <Box className="flex-between-center gap-5 p-5">
      <Typography className="!text-md !font-medium">{title}</Typography>
      {!withoutInfo && (
        <Box className="w-[35px] h-[35px] border border-primary-blue-100 bg-primary-blue-50 flex-center rounded-md">
          {InfoIcon}
        </Box>
      )}
    </Box>
  );
};

export default SectionHeader;
