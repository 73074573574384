import { RouterProvider } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  DashboardReducer,
  DashboardReducerName,
} from "./store/dashboard/slice";
import { DashboardSaga, DashboardSagaName } from "./store/dashboard/sagas";
import router from "./routes";
import { AuthProvider } from "./context/AuthContext";
import { DrawerReducer, DrawerReducerName } from "./store/drawer/slice";
import { ReportReducer, ReportReducerName } from "./store/report/slice";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

function App() {
  // Injecting the Reducers
  useInjectReducer({
    reducer: DashboardReducer,
    key: DashboardReducerName,
  });

  useInjectReducer({
    reducer: DrawerReducer,
    key: DrawerReducerName,
  });

  useInjectReducer({
    reducer: ReportReducer,
    key: ReportReducerName,
  });

  // Injecting the Sagas
  useInjectSaga({
    saga: DashboardSaga,
    key: DashboardSagaName,
  });

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
