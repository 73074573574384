import { Box } from "@mui/material";
import React from "react";
import InsightCards from "./InsightCards";
import { colorType } from "../../types/common";
import { insightCardsData } from "../../utils/data";

const InsightCardsSection = () => {
  return (
    <Box className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
      {insightCardsData.map(({ status, title, quantity, icon, growth }) => (
        <InsightCards
          status={status as colorType}
          title={title}
          quantity={quantity}
          icon={icon()}
          growth={growth}
        />
      ))}
    </Box>
  );
};

export default InsightCardsSection;
