import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TrailPartnersAuthService } from '../../services/TrailPartnersAuthService';

interface LoginParamType {
  code: string;
}

const LoginWithTrialPartners = () => {

  const location = useLocation();

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      code: params.get('code'),
    };
  };

  const handleLogin = async () => {
    const { code } = getQueryParams();

    if (!code) {
      console.error('Missing email or password in query parameters');
      return;
    }

    const loginParams: LoginParamType = { code };

    try {
      const response = await TrailPartnersAuthService(loginParams);
      console.log('Login successful:', response);
      // Handle successful login logic
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  useEffect(() => {
    handleLogin();
  }, []); // Run only on page load

  return <div>Loading...</div>;
};

export default LoginWithTrialPartners;