import { Box } from "@mui/material";
import { CalenderTimeIcon } from "../../components/Icons";
import BasicSelect from "../../components/formControls/Select";
import { campaignFilterOptions, dateFilterOptions } from "../../utils/data";

const MobileFilters = () => {
  return (
    <Box className="grid grid-cols-2 p-4 gap-3">
      <Box className="">
        <BasicSelect options={campaignFilterOptions} />
      </Box>
      <Box>
        <BasicSelect icon={CalenderTimeIcon} options={dateFilterOptions} />
      </Box>
    </Box>
  );
};

export default MobileFilters;
