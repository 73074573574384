import {
  BarGraphIcon,
  BookIcon,
  ChatsIcon,
  ComputerIcon,
  CursorIcon,
  DashboardIcon,
  DollarIcon,
  FacebookIcon,
  GoogleIcon,
  GrowthChartBigBlueIcon,
  InfoIcon,
  InstagramIcon,
  ListIcon,
  MegaphoneIcon,
  MetaIcon,
  MobileIcon,
  PlayIcon,
  ShieldIcon,
  SocialIconCombined,
  TabletIcon,
  TikTokIcon,
  TreeIcon,
} from "../components/Icons";
import routeConstants from "./routeConstants";

export const dataSourceList = [
  { name: "Google", icon: GoogleIcon, isConnected: false },
  { name: "Meta", icon: MetaIcon, isConnected: false },
  { name: "TikTok", icon: TikTokIcon, isConnected: true },
];

export const campaignHeaderOptions = [
  {
    icon: GoogleIcon,
    title: "Google Ads",
  },
  {
    icon: InstagramIcon,
    title: "Instagram Ads",
  },
  {
    icon: FacebookIcon,
    title: "Facebook Ads",
  },
  {
    icon: TikTokIcon,
    title: "TikTok Ads",
  },
];

export const adCopiesAccordionData = [
  {
    name: "Google Ads",
    icon: GoogleIcon,
    description: "Lorem Ipsum",
  },
  {
    name: "Instagram Ads",
    icon: InstagramIcon,
    description: "Lorem Ipsum",
  },
  {
    name: "Facebook Ads",
    icon: FacebookIcon,
    description: "Lorem Ipsum",
  },
  {
    name: "TikTok Ads",
    icon: TikTokIcon,
    description: "Lorem Ipsum",
  },
];

export const adCopiesInsightData = [
  {
    title: "Avg. Conversions on Ad Spend",
    amount: "17,485",
  },
  {
    title: "Avg. Spend",
    amount: "$10.5k",
  },
  {
    title: "Avg. CPA",
    amount: "4.8",
  },
  {
    title: "Avg. CPC ( All )",
    amount: "$10.5k",
  },
  {
    title: "Avg. CTR ( Outbound )",
    amount: "4.8",
  },
  {
    title: "Avg. Click",
    amount: "138",
  },
];

export const adVisitorDeviceData = [
  {
    name: "Mobile Phones",
    percentage: 25,
    color: "#20407D",
    icon: MobileIcon,
  },
  {
    name: "Tablets",
    percentage: 55,
    color: "#4B73B8",
    icon: TabletIcon,
  },
  {
    name: "Computers",
    percentage: 20,
    color: "#8D9DD0",
    icon: ComputerIcon,
  },
];

export const topAdvertisingChannelsData = [
  {
    name: "Google",
    conversions: "45,539,962",
    percentage: 60,
    color: "#f9a825",
  },
  {
    name: "Facebook",
    conversions: "44,457,124",
    percentage: 47,
    color: "#2196f3",
  },
  {
    name: "Instagram",
    conversions: "48,458",
    percentage: 13,
    color: "#e91e63",
  },
  {
    name: "TikTok",
    conversions: "37,145",
    percentage: 10,
    color: "#00bcd4",
  },
];

export const conversionsByDayData = [
  { date: "1st", Google: 10, Facebook: 5, Instagram: 15, TikTok: 20 },
  { date: "2nd", Google: 20, Facebook: 10, Instagram: 25, TikTok: 30 },
  { date: "3rd", Google: 15, Facebook: 15, Instagram: 30, TikTok: 35 },
  { date: "4th", Google: 40, Facebook: 20, Instagram: 35, TikTok: 25 },
  { date: "5th", Google: 25, Facebook: 25, Instagram: 20, TikTok: 15 },
  { date: "6th", Google: 35, Facebook: 15, Instagram: 30, TikTok: 40 },
  { date: "7th", Google: 50, Facebook: 20, Instagram: 45, TikTok: 50 },
  { date: "8th", Google: 30, Facebook: 25, Instagram: 35, TikTok: 30 },
  { date: "9th", Google: 20, Facebook: 10, Instagram: 25, TikTok: 20 },
  { date: "10th", Google: 40, Facebook: 30, Instagram: 50, TikTok: 60 },
  { date: "11th", Google: 35, Facebook: 25, Instagram: 45, TikTok: 55 },
  { date: "12th", Google: 30, Facebook: 20, Instagram: 40, TikTok: 50 },
  { date: "13th", Google: 25, Facebook: 15, Instagram: 35, TikTok: 45 },
  { date: "14th", Google: 20, Facebook: 10, Instagram: 30, TikTok: 40 },
  { date: "15th", Google: 45, Facebook: 35, Instagram: 50, TikTok: 60 },
  { date: "16th", Google: 30, Facebook: 20, Instagram: 40, TikTok: 50 },
  { date: "17th", Google: 35, Facebook: 25, Instagram: 45, TikTok: 55 },
  { date: "18th", Google: 25, Facebook: 15, Instagram: 35, TikTok: 45 },
  { date: "19th", Google: 20, Facebook: 10, Instagram: 30, TikTok: 40 },
  { date: "20th", Google: 40, Facebook: 30, Instagram: 50, TikTok: 60 },
  { date: "21st", Google: 30, Facebook: 20, Instagram: 40, TikTok: 50 },
  { date: "22nd", Google: 25, Facebook: 15, Instagram: 35, TikTok: 45 },
  { date: "23rd", Google: 20, Facebook: 10, Instagram: 30, TikTok: 40 },
  { date: "24th", Google: 45, Facebook: 35, Instagram: 50, TikTok: 60 },
  { date: "25th", Google: 35, Facebook: 25, Instagram: 45, TikTok: 55 },
  { date: "26th", Google: 30, Facebook: 20, Instagram: 40, TikTok: 50 },
  { date: "27th", Google: 25, Facebook: 15, Instagram: 35, TikTok: 45 },
  { date: "28th", Google: 20, Facebook: 10, Instagram: 30, TikTok: 40 },
  { date: "29th", Google: 50, Facebook: 40, Instagram: 55, TikTok: 65 },
  { date: "30th", Google: 40, Facebook: 30, Instagram: 50, TikTok: 60 },
];

export const conversionsByDaySocialData = [
  { platform: "Google", percent: 21.5, fill: "#F4B400" },
  { platform: "Facebook", percent: 9.6, fill: "#4B73B8" },
  { platform: "Instagram", percent: 15.75, fill: "#BA3478" },
  { platform: "TikTok", percent: 20.0, fill: "#60C0C8" },
];

export const costPerConversionData = [
  { date: "1st", Google: 10, Instagram: 20 },
  { date: "2nd", Google: 5, Instagram: 15 },
  { date: "3rd", Google: 15, Instagram: 10 },
  { date: "4th", Google: 10, Instagram: 25 },
  { date: "5th", Google: 35, Instagram: 30 },
  { date: "6th", Google: 0, Instagram: 50 },
  { date: "7th", Google: 20, Instagram: 40 },
  { date: "8th", Google: 25, Instagram: 35 },
  { date: "9th", Google: 15, Instagram: 20 },
  { date: "10th", Google: 20, Instagram: 30 },
  { date: "11th", Google: 30, Instagram: 45 },
  { date: "12th", Google: 40, Instagram: 50 },
  { date: "13th", Google: 35, Instagram: 45 },
  { date: "14th", Google: 20, Instagram: 30 },
  { date: "15th", Google: 15, Instagram: 25 },
  { date: "16th", Google: 10, Instagram: 20 },
  { date: "17th", Google: 5, Instagram: 15 },
  { date: "18th", Google: 10, Instagram: 20 },
  { date: "19th", Google: 15, Instagram: 25 },
  { date: "20th", Google: 20, Instagram: 30 },
  { date: "21st", Google: 25, Instagram: 35 },
  { date: "22nd", Google: 20, Instagram: 30 },
  { date: "23rd", Google: 30, Instagram: 50 },
  { date: "24th", Google: 40, Instagram: 60 },
  { date: "25th", Google: 50, Instagram: 70 },
  { date: "26th", Google: 45, Instagram: 65 },
  { date: "27th", Google: 40, Instagram: 60 },
  { date: "28th", Google: 35, Instagram: 55 },
  { date: "29th", Google: 30, Instagram: 50 },
  { date: "30th", Google: 25, Instagram: 45 },
];

export const costPerConversionSocialData = [
  { platform: "Google", fill: "#f9a825" },
  { platform: "Facebook", fill: "#2196f3" },
  { platform: "Instagram", fill: "#e91e63" },
  { platform: "TikTok", fill: "#00bcd4" },
];

export const overviewData = [
  {
    value: 1000,
    name: "13.5k",
  },
  {
    value: 1000,
    name: "1.2k",
  },
  {
    value: 1000,
    name: "47",
  },
  {
    value: 1000,
    name: "26",
  },
  {
    value: 1000,
    name: "1.66k",
  },
];

export const adSpentReturnData = [
  { platform: "Google", ROAS: 30, fill: "#F4B400" },
  { platform: "Facebook", ROAS: 36, fill: "#4B73B8" },
  { platform: "Instagram", ROAS: 25, fill: "#BA3478" },
  { platform: "TikTok", ROAS: 18, fill: "#60C0C8" },
];

export const costPerClickData = [
  { platform: "Google", cost: 26.75, fill: "#F4B400" },
  { platform: "Facebook", cost: 25.09, fill: "#4B73B8" },
  { platform: "Instagram", cost: 20.05, fill: "#BA3478" },
  { platform: "TikTok", cost: 5.75, fill: "#60C0C8" },
];

export const metricsData = [
  {
    "Trial Partner Metrics": ["Click score", "Convert score"],
  },
  {
    Performance: [
      "Average order value",
      "Reach",
      "Impressions",
      "Cost per mille",
      "Spend",
    ],
  },
];

export const insightCardsData = [
  {
    status: "success",
    title: "Impressions",
    quantity: "69,273",
    icon: GrowthChartBigBlueIcon,
    growth: "1.75 %",
  },
  {
    status: "warning",
    title: "Clicks",
    quantity: "748",
    icon: CursorIcon,
    growth: "1.75 %",
  },
  {
    status: "success",
    title: "Conversions",
    quantity: "12,147",
    icon: DollarIcon,
    growth: "1.75 %",
  },
  {
    status: "success",
    title: "CTR",
    quantity: "0.38 %",
    icon: DollarIcon,
    growth: "1.75 %",
  },
  {
    status: "success",
    title: "Cost",
    quantity: "$ 1.5K",
    icon: DollarIcon,
    growth: "1.75 %",
  },
  {
    status: "warning",
    title: "Cost per Conversion",
    quantity: "$ 5",
    icon: DollarIcon,
    growth: "1.75 %",
  },
  {
    status: "warning",
    title: "Avg. CPC",
    quantity: "$ 120",
    icon: DollarIcon,
    growth: "1.75 %",
  },
  {
    status: "success",
    title: "Conversions on Ad Spend",
    quantity: "1.02",
    icon: DollarIcon,
    growth: "1.75 %",
  },
];

export const generatedReportsRows = [
  {
    id: 1,
    platform: "Facebook",
    campaign: "Holiday Sale",
    clicks: 120,
    impression: 3500,
    spend: 150,
    conversions: 30,
    startDate: "2024-12-01",
    endDate: "2024-12-15",
    status: "Enable",
  },
  {
    id: 2,
    platform: "Google",
    campaign: "Winter Promo",
    clicks: 200,
    impression: 5000,
    spend: 200,
    conversions: 50,
    startDate: "2024-12-05",
    endDate: "2024-12-20",
    status: "Disabled",
  },
  {
    id: 3,
    platform: "Twitter",
    campaign: "Black Friday Deals",
    clicks: 150,
    impression: 4500,
    spend: 180,
    conversions: 40,
    startDate: "2024-11-25",
    endDate: "2024-12-05",
    status: "Disabled",
  },
  {
    id: 4,
    platform: "Instagram",
    campaign: "New Year Countdown",
    clicks: 300,
    impression: 8000,
    spend: 250,
    conversions: 70,
    startDate: "2024-12-10",
    endDate: "2024-12-31",
    status: "Enable",
  },
  {
    id: 5,
    platform: "LinkedIn",
    campaign: "Q4 Webinar",
    clicks: 80,
    impression: 2500,
    spend: 100,
    conversions: 10,
    startDate: "2024-12-01",
    endDate: "2024-12-15",
    status: "Disabled",
  },
  {
    id: 6,
    platform: "Facebook",
    campaign: "Spring Collection",
    clicks: 180,
    impression: 4700,
    spend: 160,
    conversions: 35,
    startDate: "2024-12-01",
    endDate: "2024-12-10",
    status: "Enable",
  },
  {
    id: 7,
    platform: "Google",
    campaign: "Summer Sale",
    clicks: 250,
    impression: 6000,
    spend: 220,
    conversions: 60,
    startDate: "2024-06-01",
    endDate: "2024-06-15",
    status: "Disabled",
  },
  {
    id: 8,
    platform: "Twitter",
    campaign: "Cyber Monday",
    clicks: 220,
    impression: 5200,
    spend: 210,
    conversions: 45,
    startDate: "2024-11-30",
    endDate: "2024-12-02",
    status: "Disabled",
  },
  {
    id: 9,
    platform: "Instagram",
    campaign: "Valentine's Day Deals",
    clicks: 160,
    impression: 4000,
    spend: 180,
    conversions: 50,
    startDate: "2024-02-01",
    endDate: "2024-02-14",
    status: "Enable",
  },
  {
    id: 10,
    platform: "LinkedIn",
    campaign: "Q1 Strategy",
    clicks: 110,
    impression: 3000,
    spend: 130,
    conversions: 25,
    startDate: "2024-01-01",
    endDate: "2024-01-31",
    status: "Enable",
  },
  {
    id: 11,
    platform: "Facebook",
    campaign: "Back to School",
    clicks: 200,
    impression: 5000,
    spend: 180,
    conversions: 45,
    startDate: "2024-08-01",
    endDate: "2024-08-15",
    status: "Enable",
  },
  {
    id: 12,
    platform: "Google",
    campaign: "Christmas Sale",
    clicks: 220,
    impression: 5800,
    spend: 210,
    conversions: 60,
    startDate: "2024-12-10",
    endDate: "2024-12-20",
    status: "Enable",
  },
  {
    id: 13,
    platform: "Twitter",
    campaign: "New Year Sale",
    clicks: 270,
    impression: 6500,
    spend: 230,
    conversions: 65,
    startDate: "2024-12-15",
    endDate: "2024-12-31",
    status: "Disabled",
  },
  {
    id: 14,
    platform: "Instagram",
    campaign: "Summer Promo",
    clicks: 180,
    impression: 4300,
    spend: 190,
    conversions: 40,
    startDate: "2024-06-01",
    endDate: "2024-06-15",
    status: "Disabled",
  },
  {
    id: 15,
    platform: "LinkedIn",
    campaign: "Holiday Webinar",
    clicks: 130,
    impression: 3300,
    spend: 140,
    conversions: 20,
    startDate: "2024-12-01",
    endDate: "2024-12-10",
    status: "Enable",
  },
];

export const topRecommendedReportsRows = [
  "Impressions",
  "Clicks",
  "Conversion",
  "CTR",
  "Cost",
  "Cost per Conversion",
  "Avg. CPC",
  "Conversions on Ad Spends",
];

export const reportsStepOneSocialMediaOptions = [
  { name: "All", icon: SocialIconCombined },
  { name: "Google", icon: GoogleIcon },
  { name: "Facebook", icon: FacebookIcon },
  { name: "Instagram", icon: InstagramIcon },
  { name: "TikTok", icon: TikTokIcon },
];

export const metricsMediaOptionsData = [
  "Clicks",
  "Impressions",
  "Conversions",
  "Spend",
  "ROAS",
  "Impression",
  "Conversion",
  "Spends",
];

export const reportsStepTwoCampaigns = [
  {
    name: "Rejuvenate Your Skin with Nature-lo's Organic Face Cream. Free Shipping! Shop Now.",
    img: require("../assets/sampleImage.png"),
  },
  {
    name: "Get Deep Hydration with Nature-lo’s Herbal Moisturizers. 100% Natural Ingredients!",
    img: require("../assets/sampleImage.png"),
  },
  {
    name: "Save 20% on Organic Body Lotions! Limited Time Offer at Nature-lo.",
    img: require("../assets/sampleImage.png"),
  },
  {
    name: "Summer Savings! Shop Now & Get 20% Off Organic Skincare Products.",
    img: require("../assets/sampleImage.png"),
  },
  {
    name: "Join the Nature-lo Movement – Organic Beauty Inspired by Nature.",
    img: require("../assets/sampleImage.png"),
  },
];

export const reportStepperTitleAndSubtitle = [
  {
    title: "Select Platform",
    subtitle:
      "Lorem ipsum dolor sit amet consectetur. Sapien quis laoreet tempor dolor massa dignissim in tellus tellus.",
  },
  {
    title: "Select Google Campaign",
    subtitle:
      "Kindly choose the campaigns for which you wish to generate a report.",
  },
  {
    title: "Select Metrics",
    subtitle:
      "Lorem ipsum dolor sit amet consectetur. Sapien quis laoreet tempor dolor massa dignissim in tellus tellus.",
  },
  {
    title: "Select Date Range",
    subtitle:
      "Lorem ipsum dolor sit amet consectetur. Sapien quis laoreet tempor dolor massa dignissim in tellus tellus.",
  },
];

export const campaignFilterOptions = [
  {
    title: "Campaign",
  },
  {
    title: "Conversions",
  },
  {
    title: "Reports",
  },
];

export const dateFilterOptions = [
  {
    title: "Last 30 days",
  },
  {
    title: "Last 15 days",
  },
  {
    title: "Last 7 days",
  },
];

export const sidebarItems = [
  { icon: DashboardIcon, label: "Dashboard", link: routeConstants.dashboard },
  { icon: ListIcon, label: "Conversions", link: routeConstants.conversions },
  { icon: MegaphoneIcon, label: "Campaigns", link: routeConstants.campaigns },
  { icon: PlayIcon, label: "Ad Copies", link: routeConstants.adCopies },
  {
    icon: TreeIcon,
    label: "Manage Data Sources",
    link: routeConstants.manageDataSource,
  },
  { icon: BarGraphIcon, label: "Reports", link: routeConstants.reports },
  {
    icon: BookIcon,
    label: "Learn about Trial Partners",
    link: routeConstants.learnAboutUs,
  },
  {
    icon: ChatsIcon,
    label: "Send Feedback",
    link: routeConstants.sendFeedback,
  },
  { icon: InfoIcon, label: "About Us", link: routeConstants.aboutUs },
  {
    icon: ShieldIcon,
    label: "Privacy Policy",
    link: routeConstants.privacyPolicy,
  },
];
