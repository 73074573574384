import { Box } from "@mui/material";
import React from "react";
import SectionHeader from "../SectionHeader";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { overviewData } from "../../../utils/data";

const OverviewSection = () => {
  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100 flex-column">
      <SectionHeader title="Overview" />
      <ResponsiveContainer minWidth={200} height={250}>
        <BarChart
          width={1000}
          height={250}
          data={overviewData}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default OverviewSection;
